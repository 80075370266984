<ng-container [ngSwitch]="cardType">
  <!-- --------------------------------------for List-------------------------------- -->
  <div *ngSwitchCase="'list'">
    <div class="list-product-card">
      <div
        class="effect ion-activatable ripple-parent rounded-rectangle"
        (click)="checkIfOpenFromModal(productData?.productId)"
      >
        <div class="img-section pointer">
          <ion-img
            src="{{ productData.images[0] }}"
            alt="img"
            (ionError)="loadDefaultImg($event)"
          />
          <div
            *ngIf="productData?.mrp > productData?.discountedPrice"
            class="item-discount"
          >
            <small>{{ productData?.discountLabel }}</small>
          </div>
        </div>

        <div class="item-details">
          <div class="product-title">
            <h6 class="overflow-control">
              {{ productData.itemName | titlecase }}
            </h6>
          </div>
          <div>
            <small>{{ productData.size }}</small>
          </div>

          <ion-row>
            <ion-col size="12">
              <h6 class="after-discount-price">
                <span class="small-price-icon">&#8377;</span>
                {{ productData.discountedPrice }}
                <span
                  class="price-font"
                  *ngIf="productData?.mrp > productData?.discountedPrice"
                >
                  <span class="small-price-icon-1">&#8377;</span
                  ><span class="price-drop"> {{ productData.mrp }}</span>
                </span>
              </h6>
            </ion-col>
          </ion-row>
        </div>
        <ion-ripple-effect></ion-ripple-effect>
      </div>

      <div class="card-bottom">
        <ion-row *ngIf="!isInCart && productData?.stock > 0">
          <ion-col size="12">
            <ion-button
              expand="block"
              class="custom-outline-btn-1 ion-activatable ripple-parent rounded-rectangle"
              (click)="checkIfUserLoggedIn(productData._id, productData)"
            >
              Add to Cart
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="!isInCart && productData?.stock === 0">
          <ion-col size="12">
            <ion-button
              expand="block"
              fill="clear"
              class="out-of-stock ion-activatable ripple-parent rounded-rectangle"
            >
              Out of stock
              <ion-ripple-effect></ion-ripple-effect>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="isInCart" class="add-to-cart">
          <ion-col
            size="4"
            class="minus-btn pointer ion-activatable ripple-parent rounded-rectangle"
          >
            <ion-icon
              name="remove-outline"
              (click)="decreaseListQuantity(productData)"
            ></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-col>
          <ion-col size="4" class="product-quantity">
            <span>{{ productQuantity }}</span>
          </ion-col>
          <ion-col
            size="4"
            class="add-btn pointer ion-activatable ripple-parent rounded-rectangle"
          >
            <ion-icon
              name="add-outline"
              (click)="increaseListQuantity(productData)"
              class="pointer"
            ></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-col>
        </ion-row>

        <ion-row
          class="ion-no-margin ion-no-padding"
          *ngIf="productData.isSubscription"
        >
          <ion-col size="12" class="ion-no-margin ion-no-padding">
            <p class="ion-no-margin ion-no-padding ion-text-center">
              <a
                (click)="goToSub($event)"
                class="startSub small"
              >Subscribe</a>
          </p>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </div>
  <!-- ---------------------------------------end------------------------------ -->
  <!-- -----------------------------------------For cart----------------------- -->
  <div *ngSwitchCase="'cart'">
    <ion-row class="cart-product-card">
      <ion-col size="12">
        <div class="cart-item-section">
          <!-- -----card--list -->
          <ion-row>
            <ion-col
              size="3"
              class="line-section ion-activatable ripple-parent rounded-rectangle"
            >
              <div class="pointer">
                <ion-img
                  src="{{ productData.images[0] }}"
                  alt="img"
                  (ionError)="loadDefaultImg($event)"
                  (click)="checkIfOpenFromModal(productData?.productId)"
                />
              </div>
              <ion-ripple-effect></ion-ripple-effect>
            </ion-col>
            <ion-col [size]="showCartButtons ? 6 : 9" class="cart-list-name">
              <div class="ion-activatable ripple-parent rounded-rectangle">
                <h6 (click)="checkIfOpenFromModal(productData?.productId)">
                  {{ productData.name | titlecase }}
                </h6>
                <ion-ripple-effect></ion-ripple-effect>
              </div>
              <div>
                <small>{{ productData.size }}</small>
              </div>
              <p>
                <span class="small-price-icon"> &#8377; </span>
                <span class="discountedPrice"
                  >{{
                    productData.discountedPrice * productData.quantity
                      | number : "1.0-2"
                  }}
                </span>
                <span
                  class="price-drop"
                  *ngIf="productData?.mrp > productData?.discountedPrice"
                >
                  <span class="small-price-icon-1">&#8377;</span>
                  {{
                    productData.mrp * productData.quantity | number : "1.0-2"
                  }}</span
                >
              </p>
            </ion-col>

            <ion-col size="3" class="ion-text-end delete-section" *ngIf="showCartButtons">
              <div class="ion-activatable ripple-parent rounded-rectangle">
                <ion-icon
                  class="delete-icon"
                  color="danger"
                  name="trash-outline"
                  (click)="removeProduct(productData?.productId, productData)"
                ></ion-icon>
                <ion-ripple-effect></ion-ripple-effect>
              </div>

              <ion-row class="add-to-cart">
                <ion-col
                  size="4"
                  class="minus-btn pointer ion-activatable ripple-parent rounded-rectangle"
                >
                  <ion-icon
                    name="remove-outline"
                    (click)="decreaseCartQuantity(productData)"
                  ></ion-icon>
                  <ion-ripple-effect></ion-ripple-effect>
                </ion-col>
                <ion-col size="4" class="product-quantity">
                  <span>{{ productData.quantity }}</span>
                </ion-col>
                <ion-col
                  size="4"
                  class="add-btn pointer ion-activatable ripple-parent rounded-rectangle"
                >
                  <ion-icon
                    name="add-outline"
                    (click)="increaseCartQuantity(productData)"
                    class="pointer"
                  ></ion-icon>
                  <ion-ripple-effect></ion-ripple-effect>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <!-- ----end----- -->
        </div>
      </ion-col>
    </ion-row>
  </div>
</ng-container>
