import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { AddressService } from 'src/app/services/address.service';
import { LocationPermissionService } from 'src/app/services/extensions/location-permission.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';
import { LocationService } from 'src/app/services/location.service';
import { ToastService } from 'src/app/services/toast.service';
import { StoreNotFoundComponent } from 'src/app/shared/home/store-not-found/store-not-found.component';
import { environment } from 'src/environments/environment';

declare var google: any;


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})

export class MapComponent implements OnInit, ViewWillEnter {

  @ViewChild('map', { static: true }) mapElement!: ElementRef;

  @ViewChild('mapSection') mapSection!: ElementRef;
  @ViewChild('formSection', { static: false }) formSection!: ElementRef;

  map!: google.maps.Map;
  lat: number = 28.6272735;
  lng: number = 77.37250329999999;
  marker: any;

  formattedAddress: any;

  storeDist: any = 1;
  placeVal: string = '';
  addressVal: string = '';

  isStoreAvailable: boolean = false;
  returnUrl: string = '';

  isLocationConfirmed: boolean = false;

  constructor(private locationService: LocationService,
    private router: Router,
    private toastService: ToastsService,
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private addressService: AddressService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private permissionService: LocationPermissionService
  ) { }

  ionViewWillEnter() {
    // this.addressType = 'home';
  }

  get address() {
    return this.locationService.address;
  }

  get addressPlace() {
    return this.locationService.addressPlace;
  }

  get storeDistance() {
    return this.locationService.storeDistance;
  }

  get currentStoreDistance() {
    const distance = this.storeDist;
    return (distance ? Math.round(+distance) : '1')
  }

  circle: any;

  ngOnInit() {
    // this.initMap();

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.returnUrl = params['returnUrl'] || '/';
      if (params?.addressId) {
        this.isEdit = true;
        this.addressId = params?.addressId;
      }
      else {
        this.isEdit = false;
        this.addressType = 'home'
      }
    })

    if (this.isEdit) {
      this.loadMapForEditAddress();
    }
    else {
      this.loadMapForNewAddress();
    }
  }

  timeOutId!: any;

  addressDetails() {
    this.addressService.addressDetails(this.addressId).subscribe((res: any) => {
      if (res?.success) {
        this.customerAddressForm.patchValue(res?.address);
        this.addressType = res?.address?.addressType;
        this.lat = res?.address?.location?.coordinates[1];
        this.lng = res?.address?.location?.coordinates[0];
        this.addressVal = res?.address?.googleAddress;
        this.isStoreAvailable = true;
        this.initMap()
      }
      else {
        this.toastService.showToast(res?.message)
      }
    })
  }

  loadMapForNewAddress() {
    if (this.locationService.isLocationAvailable) {
      const locationData: any = this.locationService.location;
      this.lat = locationData.latitude;
      this.lng = locationData.longitude;

      this.addressVal = localStorage.getItem('currentAddress') || ''
      this.storeDist = localStorage.getItem('storeDistance');
      this.placeVal = this.trimAddress(this.addressVal);

      this.initMap();
    } else {
      this.locationService.requestPermissions();
    }
  }

  loadMapForEditAddress() {
    this.addressDetails();
  }

  initMap(): void {
    const mapOptions = {
      center: new google.maps.LatLng(this.lat, this.lng),
      zoom: environment.googleMapZoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false,
      zoomControl: false,
      fullscreenControl: false,
      keyboardShortcuts: false,
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    this.circle = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.15,
      map: this.map,
      center: this.map.getCenter(),
      radius: 12,
    });

    google.maps.event.addListener(this.map, 'dragstart', () => {
      // Remove the circle from the map when the user starts dragging
      this.circle.setMap(null);
    });

    google.maps.event.addListener(this.map, 'idle', () => {

      clearTimeout(this.timeOutId)

      this.timeOutId = setTimeout(() => {
        const center = this.map.getCenter();
        this.lat = center.lat();
        this.lng = center.lng();

        this.circle.setCenter(center);
        this.circle.setMap(this.map);

        this.markerAddress(this.lat, this.lng)
      }, 800)

    });

  }

  updateMarkerPosition() {
    const newCenter = { lat: this.lat, lng: this.lng };
    this.map.setCenter(newCenter);
    // this.marker.setPosition(newCenter)
  }

  markerAddress(latitude: any, longitude: any) {
    const point = { lat: latitude, lng: longitude };
    this.locationService.pointLocationAddress(point).subscribe(async (res: any) => {
      if (res?.isStoreAvailable) {
        this.showProgressBar = false;
        this.isStoreAvailable = true;
        this.storeDist = res?.distance;
        this.addressVal = res?.address;
        this.cdr.detectChanges();
        this.placeVal = this.trimAddress(this.addressVal);
        this.formattedAddress = res?.formattedAddress
      }
      else {
        this.showProgressBar = false;
        this.isStoreAvailable = false;
        const isModal = await this.modalController.getTop();
        if (!isModal) {
          this.storeNotFound(res?.address)
        }
      }

    });
  }

  trimAddress(word: string) {
    const words = word.split(' ');
    const firstTwoWords = words.slice(0, 2).join(' ');
    return firstTwoWords;
  }

  completeAddress() {
    const navigationExtras = {
      state: {
        locationData: {
          latitude: this.lat,
          longitude: this.lng,
          currentAddress: this.addressVal,
          distance: this.storeDist,
          formattedAddress: this.formattedAddress
        }
      }
    };
    this.router.navigate(['/address-summary'], navigationExtras)
    // this.router.navigate(['/address-summary']);
  }

  // ---------------------------------------Current Location----------------------------------
  async moveToCurrentLocation() {
    // if (this.locationService.isLocationAvailable) {
    //   const locationData: any = this.locationService.location;
    //   this.lat = locationData.latitude;
    //   this.lng = locationData.longitude;
    //   this.updateMarkerPosition();
    //   this.markerAddress(this.lat, this.lng)

    // } else {
    //   this.locationService.requestPermissions();
    // }
    // this.locationService.requestPermissions();
    // this.checkStoreAvailabilityOnCurrentLocation();


    this.showProgressBar = true;
    console.log("called located me")
    const isLocationAllowed = await this.permissionService.checkIfLocationAllowed();

    console.log("value of isLocationAllowed", isLocationAllowed)

    if (isLocationAllowed) {
      const currentAddress: any = await this.permissionService.findMyCurrentLocation();
      this.lat = currentAddress.latitude;
      this.lng = currentAddress.longitude;
      this.updateMarkerPosition();
      this.markerAddress(this.lat, this.lng)
      // console.log(currentAddress)
    }
    else {
      this.showProgressBar = false;
      const permissionGranted = await this.permissionService.askForLocationPermission();
      console.log(permissionGranted)
      if (permissionGranted) {
        // this.moveToCurrentLocation();
        // If permission granted to app check if location services are turned ON
        // If yes get current (lat,long) and update marker position and if not show message to turn it on
        const locationServicesEnabled = await this.permissionService.turnOnLocationServices();

        if (locationServicesEnabled) {
          const currentAddress: any = await this.permissionService.findMyCurrentLocation();
          this.lat = currentAddress.latitude;
          this.lng = currentAddress.longitude;
          this.updateMarkerPosition();
          this.markerAddress(this.lat, this.lng);
        }
        else {
          this.toastService.showToast("Please enable location services to use Locate Me feature")
        }
      }
      else {
        this.toastService.showToast("Cannot use this feature when location permission denied")
      }

    }
  }

  // -----------------------------------New--Ui---------------------------------------------------------------

  isEdit: boolean = false;
  addressType: string = '';
  addressId: string = '';
  overlayVisible: boolean = false;



  customerAddressForm = new FormGroup({
    receiverName: new FormControl('', Validators.compose([Validators.required])),
    receiverPhoneNumber: new FormControl('', Validators.compose([Validators.required, Validators.pattern('(^[6-9][0-9]{9}$)')])),
    floor: new FormControl(''),
    nearbyLandmark: new FormControl(''),
    completeAddress: new FormControl('', Validators.compose([Validators.required])),
    // isDefault: new FormControl(false)
  })

  validations = {
    'receiverPhoneNumber': [
      { type: 'required', message: 'Please enter mobile no.' },
      { type: 'pattern', message: 'Please enter valid 10-digit mobile number' },
    ],
    'receiverName': [
      { type: 'required', message: 'Please enter customer name' },
    ],
    'completeAddress': [
      { type: 'required', message: 'Please enter house/building/office no.' },
    ],
  };

  changeLocationOnMap() {
    this.isLocationConfirmed = false;
    if (!this.isEdit) {
      this.resetAddressForm();
    }
    this.map.setOptions({
      draggable: true,
      scrollwheel: true,
      disableDoubleClickZoom: false,
    });

    setTimeout(() => {
      if (this.mapSection && this.mapSection.nativeElement) {
        this.mapSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100)
  }

  fillAddressDetails() {
    if (!this.isEdit) {
      const mobile = localStorage.getItem('mobile')
      if (mobile) {
        this.customerAddressForm.controls.receiverPhoneNumber.patchValue(mobile)
      }
    }

    this.isLocationConfirmed = true;
    this.map.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
    });

    setTimeout(() => {
      if (this.formSection && this.formSection.nativeElement) {
        this.formSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100)
  }

  changeAddressType(type: string) {
    this.addressType = type;
  }

  checkAddressForm() {
    if (this.customerAddressForm.invalid) {
      this.customerAddressForm.markAllAsTouched();
    }
    else {
      if (this.isEdit) {
        this.updateAddress();
      }
      else {
        this.addAddress();
      }
    }

  }

  addAddress() {
    const data = {
      googleAddress: this.addressVal,
      addressType: this.addressType,
      lat: this.lat,
      lng: this.lng,
      formattedAddress: this.formattedAddress
    }

    const payload = Object.assign(this.customerAddressForm.value, data)

    this.addressService.addAddress(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastService.showToast(res?.message, 1500, this.returnUrl === "cart" ? "-8rem" : "-5rem");
        if (this.returnUrl === 'cart') {
          const navigationExtras = {
            state: {
              addressData: res?.data
            }
          };
          this.router.navigate(['cart'], navigationExtras)
        }
        else {
          const navigationExtras = {
            state: {
              reload: true
            }
          };
          this.router.navigate(['account/address-book'], navigationExtras)
        }

      }
      else {
        this.toastService.showToast(res?.message, 1500, this.returnUrl === "cart" ? "-8rem" : "-5rem");

      }
    })

  }

  updateAddress() {
    const data = {
      googleAddress: this.addressVal,
      addressType: this.addressType,
      lat: this.lat,
      lng: this.lng
    }

    const payload = Object.assign(this.customerAddressForm.value, data)

    this.addressService.updateAddress(payload, this.addressId).subscribe((res: any) => {
      if (res.success) {
        this.toastService.showToast(res?.message,1500, this.returnUrl === "cart" ? "-8rem" : "-5rem");
        //     const navigationExtras = {
        //       state: {
        //         redirectUrl: currentUrl
        //       }
        //     };
        // this.router.navigate(['cart'],navigationExtras)
        if (this.returnUrl === 'cart') {
          this.router.navigate(['cart'])
        }
        else {
          this.router.navigate(['account/address-book'])
        }
      }
      else {
        this.toastService.showToast(res?.message, 1500, this.returnUrl === "cart" ? "-8rem" : "-5rem");
      }
    })

  }

  resetAddressForm() {
    this.customerAddressForm.reset()
  }

  // ---------------------------------Location search----------------------------------

  searchInput: any = '';
  dataFound: number = 2;
  searchSuggestions: any;
  showProgressBar: boolean = false;

  searchLocation() {
    if (this.searchInput === '') {
      this.searchSuggestions = [];
      return;
    }
    this.locationService.searchPlace({ input: this.searchInput }).subscribe((res: any) => {
      if (res.success) {
        this.searchSuggestions = res.data.predictions;
        if (this.searchSuggestions.length === 0) {
          this.dataFound = 0;
        }
        else {
          this.dataFound = 1;
        }
      }
    },
      (error) => {
        this.toastService.showToast(error?.message)
      })


  }

  selectAddress(prediction: any) {
    this.showProgressBar = true
    this.resetSearchField();
    this.newAddress(prediction.place_id);
  }

  newAddress(placeId: string) {
    this.locationService.placeDetails({ placeId: placeId }).subscribe((res: any) => {
      if (res.success) {
        const location = {
          latitude: res?.location?.lat,
          longitude: res?.location?.lng,
          accuracy: 100
        }
        this.isStoreAvailable = true;
        this.lat = res.location.lat;
        this.lng = res.location.lng;
        this.formattedAddress = res?.formattedAddress
        this.showProgressBar = false;
        // this.markerAddress(this.lat, this.lng)
        this.updateMarkerPosition();
      }
      else {
        this.storeNotFound(res?.address);
        this.showProgressBar = false;
      }
    },
      (error) => {
        this.toastService.showToast(error?.message)
        this.showProgressBar = false;
      })
  }

  async storeNotFound(place: string) {
    const modal = await this.modalController.create({
      component: StoreNotFoundComponent,
      backdropDismiss: true,
      cssClass: 'storeNotFound-message-modal',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      keyboardClose: false,
      componentProps: {
        sectionType: 'message',
        isStoreAvailable: 0,
        placeName: place
      },
      canDismiss: (data?: any, role?: string) => {
        return new Promise<boolean>(resolve => resolve(role === "close" || role === 'backdrop'))
      }
    });
    return await modal.present();
  }

  resetSearchField() {
    this.searchInput = '';
    this.searchSuggestions = [];
    this.dataFound = 2;
  }


}
