import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { ProductDetailComponent } from '../../product-detail/product-detail.component';
import { AlertController, ModalController } from '@ionic/angular';
import { ToastsService } from 'src/app/services/helpers/toasts.service';
import { ProductService } from 'src/app/services/product.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { GoogleAnalyticsService } from 'src/app/services/extensions/google-analytics.service';
import { SubscriptionService } from '../../../subscription/services/subscription.service';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { SearchService } from 'src/app/services/search.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input('subscription-check') subscriptionCheck!: boolean;
  @Input() cardType!: string;
  @Input() productData: any;

  @Input() isOpenFromModal!: boolean;

  @Input() eventData:any;

  @Input() parentScreenName:string='';

  @Output() messageEvent = new EventEmitter<boolean>();
  @Output() loadNewEvent = new EventEmitter<{ productId: string, load: boolean }>();

  @Input() showCartButtons:boolean = true;

  isInCart: boolean = false;
  productQuantity: number = 1


  constructor(private cartService: CartService,
    private modalController: ModalController,
    private toastService: ToastsService,
    private productService: ProductService,
    private alertController: AlertController,
    private router: Router,
    private authService: AuthService,
    private analyticService: GoogleAnalyticsService,
    public subscriptionService: SubscriptionService,
    private mixpanelService: MixedPanelsService,
    private searchService: SearchService
  ) { }

  ngOnInit() {
    //only check in subscription section
    /* if (this.subscriptionCheck) {
      this.subscriptionService.checkIfProductIsInSubscription(this.productData._id).then((isIn: boolean) => {
        this.productData.isInSubscription = isIn;//we need to show the button to the subs page
      });
     } */
    if (this.cardType === 'list') {
      this.productQuantity = this.productData?.quantity;
      this.cartService.getCartItems().subscribe(cartItems => {
        const productQuantity = this.cartService.getProductQuantity(this.productData?._id);
        if (productQuantity === 0) {
          this.isInCart = false;
        }
        else {
          this.isInCart = true;
          this.productQuantity = productQuantity
        }
      });
    }

  }

  updateQuantity(id: any) {
    this.cartService.getCartItems().subscribe(cartItems => {
      const productQuantity = this.cartService.getProductQuantity(this.productData?._id);
      if (productQuantity === 0) {
        this.isInCart = false;
      }
      else {
        this.isInCart = true;
        this.productQuantity = productQuantity
      }
    });

  }

  checkIfUserLoggedIn(id: any, productData: any = '') {
    this.sendCartEvent(productData?.productId)
    const isLoggedIn = this.authService.getToken()
    if (isLoggedIn) {
      this.addToCart(id, productData);
    }
    else {
      this.showAlert();
    }
  }

  addToCart(id: string, product: any = '') {
    console.log(product);

    const currentUrl = this.splitCurrentUrl();
    const purchaseEventData : any = {};

    if(currentUrl === 'search'){
      purchaseEventData.sourceType = 'search';
      purchaseEventData.queryId = this.eventData?.queryId;
    }
    else{
      purchaseEventData.sourceType = 'default';
      purchaseEventData.queryId = "";
    }

    let payload = {
      productId: id,
      quantity: 1,
    };
    Object.assign(payload, purchaseEventData);
    console.log("new payload", payload)
    this.cartService.addtocart(payload).subscribe((res: any) => {
      if (res.success) {
        // this.cartService.cartProductList();
        this.cartService.reloadCartData();
        this.mixpanelService.track('Product Added to Cart', {
          'product_name': product?.itemName,
          'product_id': product?.productId,
          'quantity': 1,
        });
        this.mixpanelService.track("b_add_to_cart", { "screenName" : this.parentScreenName,'product_name': product?.itemName });

        this.analyticService.trackEvent('Add_to_cart', 'User_interaction_event', 'Product added', id, { key: 'Product name', value: product?.itemName });
      }
    });
  }

  increaseListQuantity(product: any) {
    if (this.productQuantity < product?.stock) {
      const payload = {
        productId: product.productId,
        quantity: this.productQuantity + 1
      }
      this.cartService.updateCart(payload).subscribe((res: any) => {
        if (res.success) {
          // this.cartService.cartProductList();
          this.cartService.reloadCartData();
          this.toastService.showToast(res?.message);
          this.productQuantity = this.productQuantity + 1;
          this.messageEvent.emit(true)
        }
      });

    }
    else {
      this.toastService.showToast('Product is out of stock');
    }
  }

  decreaseListQuantity(product: any) {
    if (this.productQuantity >= 1) {
      const payload = {
        productId: product.productId,
        quantity: this.productQuantity - 1
      }
      this.cartService.updateCart(payload).subscribe((res: any) => {
        if (res.success) {
          this.cartService.reloadCartData();
          // this.cartService.cartProductList();
          if (this.productQuantity === 1) {
            this.isInCart = false;
          }
          this.productQuantity = this.productQuantity - 1;
          this.toastService.showToast(res?.message)
          this.messageEvent.emit(true)
        }
      });
    }


  }

  increaseCartQuantity(product: any) {
    if (product?.quantity < product?.stock) {
      const payload = {
        productId: product.productId,
        quantity: product?.quantity + 1
      }
      this.cartService.updateCart(payload).subscribe((res: any) => {
        if (res.success) {
          // this.cartService.cartProductList();
          this.toastService.showToast(res?.message);
          this.messageEvent.emit(true)
        }
      });
    }
    else {
      this.toastService.showToast('Product is out of stock')
    }
  }

  decreaseCartQuantity(product: any) {
    const payload = {
      productId: product.productId,
      quantity: product?.quantity - 1
    }
    this.cartService.updateCart(payload).subscribe((res: any) => {
      if (res.success) {
        // this.cartService.cartProductList();
        this.toastService.showToast(res?.message);
        this.messageEvent.emit(true)
      }
    });

  }



  increment(product: any) {
    let id = product.productId;
    let quant = product.quantity;
    let cartproduct = {
      productId: id,
      quantity: quant + 1
    };

    this.cartService.updateCart(cartproduct).subscribe((res: any) => {
      if (res.success) {
        this.cartService.cartList();
        this.messageEvent.emit(true)
      }
    });

  }

  decrement(product: any) {
    let id = product.productId;
    let quant = product.quantity;
    let cartproduct = {
      productId: id,
      quantity: quant - 1
    };

    this.cartService.updateCart(cartproduct).subscribe((res) => {
      if (res.success) {
        this.cartService.cartList();
        this.messageEvent.emit(true)
      }

    });
  }

  async removeProduct(id: string, product: any = '') {
    const alert = await this.alertController.create({
      header: 'Confirm Removal',
      message: 'Remove this product from your cart?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
          }
        },
        {
          text: 'Yes',
          handler: () => {
            this.cartService.removeProductFromCart(id).subscribe((res: any) => {
              if (res?.success) {
                this.cartService.cartList();
                this.messageEvent.emit(true);
                this.toastService.showToast(res?.message,1000, this.parentScreenName === "Cart Page" ? "-8rem" : "-5rem");
                this.analyticService.trackEvent('Remove_from_cart', 'User_interaction_event', 'Product removed', id, { key: 'Product name', value: product?.name });
                this.mixpanelService.track('Product Removed from Cart', {
                  'product_name': product?.name,
                  'product_id': product?.productId,
                  'quantity': product?.quantity,
                });
              }
              else {

              }
            })
          }
        }
      ]
    });
    await alert.present();
  }



  checkIfOpenFromModal(productId: string) {
    this.sendClickEvent(productId)
    
    if (this.isOpenFromModal) {
      this.loadNewEvent.emit(
        {
          productId: productId,
          load: true
        }
      )
    }
    else {
      this.openModal(productId)
    }

  }

  // ------------------------------------Algolia events----------------------------------------------

  splitCurrentUrl(){
    const currentUrl = this.router.url;
    const path = currentUrl.split('?')[0];
    const urlParts = path.split('/');

    return urlParts[1];
  }

  findProductIndex(){
    let indexName = '';

    if (this.productData?.stock > 0) {
      indexName = environment.algoliaSearch.dbIndex
    } else {
      indexName = environment.algoliaSearch.inactiveProductIndex
    }
    return indexName;
  }

  sendClickEvent(productId:string){
    const currentUrl = this.splitCurrentUrl();
    const index = this.findProductIndex();

    console.log(this.eventData)

    if(currentUrl === 'search'){
      if(this.eventData){
        this.searchService.sendClickEventAfterSearch([productId],'clicked search result', this.eventData?.queryId ,[this.eventData?.position],index);
      }
      else{
        this.searchService.sendClickEventAfterSearch([productId],'clicked search result','',[],index);
      }
    }
    else{
      this.searchService.sendGenericClickEvent([productId], 'clicked product', index)
    }
  }

  sendCartEvent(productId:string){
    const currentUrl = this.splitCurrentUrl();
    const index = this.findProductIndex();

    console.log(this.eventData)

    console.log(productId)

    if(currentUrl === 'search'){
      if(this.eventData){
        this.searchService.sendAddToCartEventAfterSearch([productId],'searched product added to cart', this.eventData?.queryId ,[this.eventData?.position],index);
      }
      else{
        this.searchService.sendAddToCartEventAfterSearch([productId],'searched product added to cart','',[],index);
      }
    }
    else{
      this.searchService.sendGenericAddToCartEvent([productId], 'product added to cart', index)
    }
  }

  // ----------------------------------------------------------------------------------


  async openModal(pId: string) {
    let modal = await this.modalController.create({
      component: ProductDetailComponent,
      cssClass: 'product-detail',
      backdropDismiss: true,
      componentProps: {
        productId: pId,
        eventData: this.eventData
      },
    })

    modal.onDidDismiss()
      .then((data: any) => {
        if (data?.check) {
        }
      });

    return await modal.present();
  }

  loadDefaultImg(event: any) {
    this.productService.loadDefaultImg(event)
  }

  // -------------------------------Alert if user is not logged in----------------------

  async showAlert() {
    const alert = await this.alertController.create({
      cssClass: 'custom-login-alert',
      header: 'Login Required',
      message: 'You need to log in to add products to your cart.',
      buttons: [
        {
          text: 'Keep browsing',
          role: 'cancel',
          cssClass: 'secondary-option',
          handler: () => {
          }
        }, {
          cssClass: 'primary-option',
          text: 'Proceed to login',
          handler: () => {
            const currentUrl = this.router.url;
            const navigationExtras = {
              state: {
                redirectUrl: currentUrl
              }
            };
            this.checkIfModalOpen();
            this.router.navigate(['login'], navigationExtras)
          }
        }
      ]
    });

    await alert.present();


  }

  async checkIfModalOpen() {
    const modal = await this.modalController.getTop()
    if (modal) {
      modal.dismiss()
    }
  }

  public goToSub($event: any): void {
    this.mixpanelService.track("b_subscribe", { "screenName" : this.parentScreenName,'product_name': this.productData?.itemName });
    this.router.navigate(['/subscription', 'product', this.productData._id]);
  }
}
