import { Injectable } from '@angular/core';
import { Mixpanel } from '@awesome-cordova-plugins/mixpanel/ngx';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MixedPanelsService {

  constructor(private mixpanelService: Mixpanel) { }

  initializeMixpanel() {
    // Initialize Mixpanel with your project token
    this.mixpanelService.init(environment.mixpanelToken)

    const userId = localStorage.getItem('userId');
    if(userId){
      //If logged in , identify the user based on the unique id
      this.identify(userId)
    }
  }

  track(event: string, properties?: any) {
    const userId = localStorage.getItem('userId');
    if(userId){
      Object.assign(properties,{userId:userId})
    }
    // console.log(properties, event);
    this.mixpanelService.track(event, properties);
  }

  identify(userId: string) {
    this.mixpanelService.identify(userId);
  }

  alias(alias: string) {
    this.mixpanelService.alias(alias);
  }

  peopleSet(properties: any) {
    // this.mixpanelService.people.set(properties);
  }
}
