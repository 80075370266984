import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import { Subject, interval, takeUntil, takeWhile } from 'rxjs';
import { OrderService } from 'src/app/services/order.service';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';
import { config } from 'src/app/config'
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { UtilityService } from 'src/app/services/utility.service';

declare var google: any;


@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss'],
})
export class OrderTrackingComponent implements OnInit, ViewDidEnter, OnDestroy, ViewDidLeave {

  @ViewChild('map', { static: true }) mapElement!: ElementRef;

  orderId: any;

  billingData: any;
  orderData: any;

  map!: google.maps.Map;
  lat: number = 28.6272735;
  lng: number = 77.37250329999999;

  ORIGIN_LAT!: number;
  ORIGIN_LNG!: number;

  CURRENT_LAT!: number; // Driver starting latitude
  CURRENT_LNG!: number; // Driver starting longitude
  DESTINATION_LAT!: number; // Destination latitude
  DESTINATION_LNG!: number; // Destination longitude

  // originMarker!: google.maps.Marker;
  bikeMarker: any;

  directionsService!: google.maps.DirectionsService;
  directionsRenderer!: google.maps.DirectionsRenderer;

  bikerIcon: any;

  isPickedByDriver: boolean = false;

  mapCenter: any;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private orderService: OrderService,
    private productService: ProductService,
    private utilityService: UtilityService,
    private mixpanelService: MixedPanelsService
  ) { }

  ngOnInit() {
    // this.initMap();
    this.bikerIcon = config.bikerIcon;
    this.activateRoute.queryParams.subscribe((params: any) => {
      if (params?.orderId) {
        this.orderId = params?.orderId;
        const route = params['backButtonRoute']
        if (route) {
          this.backUrl = route;
        }
        else {
          this.backUrl = '/account/order-list'
        }
        this.trackOrder()
      }
    })

    this.mixpanelService.track("s_track_order", {"orderID": this.orderData?.orderID})
  }

  ionViewDidEnter() {
    // this.loadMap();
    // this.displayRoute();
  }

  // originIcon = {
  //   url: '../../../../../assets/img/daykart/bike-rider.svg',
  //   scaledSize: new google.maps.Size(70, 70), // Adjust the size if necessary
  //   rotation: 45
  // };

  storeIcon = {
    url: '../../../../../assets/img/daykart/store.png',
    scaledSize: new google.maps.Size(40, 40), // Adjust the size if necessary
  };

  destinationIcon = {
    url: '../../../../../assets/img/daykart/location.png',
    scaledSize: new google.maps.Size(40, 40) // Adjust the size if necessary
  };

  timeOutId!: any;
  previousOriginMarker: any;

  stopTracking = false; // Flag to track if tracking should be stopped
  private destroy$ = new Subject<void>(); // Subject to unsubscribe from observables

  routePolyline: any;
  backUrl: any;

  navigateTo(id: any, routeTo: any) {
    this.mixpanelService.track("b_view_details", { "screenName" : "Track Order", "orderID": this.orderData?.orderID});

    const route = this.router.url
    this.router.navigate([routeTo], { queryParams: { orderId: id, backButtonRoute: route } })
  }

  routeTo() {
    this.router.navigateByUrl(this.backUrl)
  }

  trackOrder() {
    this.orderService.orderTracking(this.orderId).subscribe((res: any) => {
      if (res?.success) {
        this.billingData = res?.billDetails;
        this.orderData = res?.data;
        this.isPickedByDriver = res?.data?.isPickedByDriver;

        const coordinates = res?.data?.locationTracking?.coordinates;
        const length = coordinates.length;


        this.ORIGIN_LNG = res?.data?.store?.location?.coordinates[0];
        this.ORIGIN_LAT = res?.data?.store?.location?.coordinates[1];

        if (coordinates.length > 0) {
          this.CURRENT_LNG = coordinates[length - 1][0];
          this.CURRENT_LAT = coordinates[length - 1][1];
        }


        this.DESTINATION_LNG = res?.data?.shippingAddress?.location?.coordinates[0];
        this.DESTINATION_LAT = res?.data?.shippingAddress?.location?.coordinates[1];

        this.startTracking();

      }
      else {

      }
    })
  }

  startTracking() {
    this.loadMap();
    this.displayRoute();

    interval(20000)
      .pipe(
        // Stop the interval when stopTracking is true
        takeWhile(() => !this.stopTracking),
        // Unsubscribe from the interval when component is destroyed
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.fetchAndUpdateDriverLocation()
      });
  }

  loadMap(): void {
    const mapOptions = {
      center: new google.maps.LatLng(this.ORIGIN_LAT, this.ORIGIN_LNG),
      zoom: environment.googleMapZoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      mapTypeControl: false,
      zoomControl: false,
      fullscreenControl: false,
      keyboardShortcuts: false,
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    this.mapCenter = this.map.getCenter();

    this.directionsService = new google.maps.DirectionsService();
    this.directionsRenderer = new google.maps.DirectionsRenderer({
      map: this.map,
      suppressMarkers: true,
    });
  }



  displayRoute() {
    const request: google.maps.DirectionsRequest = {
      origin: { lat: this.ORIGIN_LAT, lng: this.ORIGIN_LNG },
      destination: { lat: this.DESTINATION_LAT, lng: this.DESTINATION_LNG },
      travelMode: google.maps.TravelMode.DRIVING,
    };

    //  this.originMarker = new google.maps.Marker({
    //   position: request.origin,
    //   map: this.map,
    //   icon: this.bikerIcon
    // });

    const storeMarker = new google.maps.Marker({
      position: request.origin,
      map: this.map,
      icon: this.storeIcon
    });

    const destinationMarker = new google.maps.Marker({
      position: request.destination,
      map: this.map,
      icon: this.destinationIcon
    });

    if (this.isPickedByDriver) {

      const initialRotation = this.calculateDirectionAngle(this.ORIGIN_LAT, this.ORIGIN_LNG, this.DESTINATION_LAT, this.DESTINATION_LNG)
      this.bikerIcon.rotation = initialRotation;

      this.bikeMarker = new google.maps.Marker({
        position: { lat: this.CURRENT_LAT, lng: this.CURRENT_LNG },
        map: this.map,
        icon: this.bikerIcon
      });

      this.previousOriginMarker = this.bikeMarker;

    }

    this.directionsService.route(request, (response: any, status) => {
      if (status === 'OK') {
        this.directionsRenderer.setDirections(response);

        const route = response.routes[0]; // Get the first route
        // const path = route.legs[0].steps[0].polyline.points

        this.routePolyline = this.decodePolyline(route.overview_polyline);

      } else {
        console.error('Directions request failed due to ' + status);
      }
    });

    // this.mapCenter = this.map.getCenter();
  }

  fetchAndUpdateDriverLocation() {
    this.orderService.orderTracking(this.orderId).subscribe((res: any) => {
      if (res?.success) {
        this.orderData.deliveryStatus = res?.data?.deliveryStatus
        this.orderData.estimatedDeliveryTime = res?.data?.estimatedDeliveryTime;
        this.orderData.deliveryStatusDescription = res?.data?.deliveryStatusDescription;
        this.orderData.orderStatus = res?.data?.orderStatus;

        if (res?.data?.disableTracking === true) {
          this.stopTracking = true;
          this.destroy$.next(); // Emit signal to complete the observable
        }
        else {
          this.isPickedByDriver = res?.data?.isPickedByDriver;

          if (res?.data?.isPickedByDriver) {

            const coordinates = res?.data?.locationTracking?.coordinates;
            const length = coordinates.length;

            const newLat = coordinates[length - 1][1];
            const newLng = coordinates[length - 1][0];
            const angleData = this.calculateDirectionAngle(this.CURRENT_LAT, this.CURRENT_LNG, newLat, newLng)

            // this.CURRENT_LNG = newLng;
            // this.CURRENT_LAT = newLat;

            const data: any = this.findNearestLatLng(newLat, newLng, this.routePolyline)

            this.CURRENT_LAT = data[0];
            this.CURRENT_LNG = data[1];


            if (this.previousOriginMarker) {
              this.previousOriginMarker.setMap(null);
            }

            this.bikerIcon.rotation = angleData

            this.bikeMarker = new google.maps.Marker({
              position: { lat: this.CURRENT_LAT, lng: this.CURRENT_LNG },
              map: this.map,
              icon: this.bikerIcon
            });

            this.previousOriginMarker = this.bikeMarker
          }
          else {

          }
        }
      }
      else {

      }
    })
  }

  calculateCenter(startLat: number, startLng: number, endLat: number, endLng: number) {
    const centerLat = (startLat + endLat) / 2;
    const centerLng = (startLng + endLng) / 2;
    return { lat: centerLat, lng: centerLng };
  }

  centerMapOnPath() {
    const bounds = new google.maps.LatLngBounds();

    bounds.extend(new google.maps.LatLng(this.ORIGIN_LAT, this.ORIGIN_LNG));
    bounds.extend(new google.maps.LatLng(this.DESTINATION_LAT, this.DESTINATION_LNG));

    this.map.fitBounds(bounds);

    // const center = this.calculateCenter(this.ORIGIN_LAT, this.ORIGIN_LNG, this.DESTINATION_LAT, this.DESTINATION_LNG);
    // this.map.setCenter(center);
  }

  calculateDirectionAngle(lat1: number, lng1: number, lat2: number, lng2: number): number {
    const y = Math.sin(lng2 - lng1) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);
    let angle = Math.atan2(y, x);
    angle = (angle * 180) / Math.PI; // Convert radians to degrees

    // Convert angle to range [0, 360]
    if (angle < 0) {
      angle += 360;
    }

    return angle;
  }

  loadDefaultImg(event: any) {
    this.productService.loadDefaultImg(event)
  }

  callDriver(mobileNo: number) {
    this.mixpanelService.track("b_call_driver", { "screenName" : "Track Order", "orderID": this.orderData?.orderID});
    this.utilityService.callToMobileNumber(mobileNo)
  }

  callHelpline(mobileNo: string) {
    this.mixpanelService.track("b_contact_us", { "screenName" : "Track Order", "orderID": this.orderData?.orderID});
    this.utilityService.callToMobileNumber(mobileNo);
  }


  getDirection(newLat: any, newLng: any): string {
    let latDirection: string = '';
    let lngDirection: string = '';

    if (newLat > this.CURRENT_LAT) {
      latDirection = 'north';
    } else if (newLat < this.CURRENT_LAT) {
      latDirection = 'south';
    }

    if (newLng > this.CURRENT_LNG) {
      lngDirection = 'east';
    } else if (newLng < this.CURRENT_LNG) {
      lngDirection = 'west';
    }

    if (latDirection && lngDirection) {
      return `${latDirection}-${lngDirection}`;
    } else if (latDirection) {
      return latDirection;
    } else if (lngDirection) {
      return lngDirection;
    } else {
      return "The points are identical.";
    }
  }

  ionViewDidLeave(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  ngOnDestroy(): void {
    // Unsubscribe from all observables to prevent memory leaks
    // this.destroy$.next();
    // this.destroy$.complete();
  }

  // -----------------------------------Route optimization---------------------------------------

  findNearestLatLng(driverLat: any, driverLng: any, routeLatLngs: any) {
    let nearestLatLng = null;
    let shortestDistance = Infinity;

    routeLatLngs.forEach((routeLatLng: any, index: any) => {
      const distance = this.haversineDistance(driverLat, driverLng, routeLatLng[0], routeLatLng[1]);
      if (distance < shortestDistance) {
        shortestDistance = distance;
        nearestLatLng = routeLatLng;
      }
    });

    return nearestLatLng;
  }

  haversineDistance(lat1: any, lon1: any, lat2: any, lon2: any) {
    const R = 6371; // Radius of the Earth in km
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  }

  deg2rad(deg: any) {
    return deg * (Math.PI / 180);
  }

  decodePolyline(encoded: any) {
    var index = 0,
      lat = 0,
      lng = 0,
      coordinates = [],
      shift = 0,
      result = 0,
      byte = null,
      latitude_change,
      longitude_change,
      factor = Math.pow(10, 5); // Precision of 5 decimals

    while (index < encoded.length) {
      byte = null;
      shift = 0;
      result = 0;

      do {
        byte = encoded.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

      shift = result = 0;

      do {
        byte = encoded.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

      lat += latitude_change;
      lng += longitude_change;

      coordinates.push([lat / factor, lng / factor]);
    }
    return coordinates;
  }

}
