import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { register } from 'swiper/element/bundle';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { HomeService } from './services/home.service';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { UtilityService } from './services/utility.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Subscription } from 'rxjs';
import { AppUpdateComponent } from './extra/modals/app-update/app-update.component';
import { GoogleAnalyticsService } from './services/extensions/google-analytics.service';
import { environment } from 'src/environments/environment';
import { NotificationsService } from './services/extensions/notifications.service';


import { BranchIo } from '@awesome-cordova-plugins/branch-io/ngx';
import { MixedPanelsService } from './services/extensions/mixed-panels.service';
import { LocationPermissionService } from './services/extensions/location-permission.service';
import { LocationService } from './services/location.service';
import { LocationPermissionModalComponent } from './extra/modals/location-permission-modal/location-permission-modal.component';
import { StoreNotFoundComponent } from './shared/home/store-not-found/store-not-found.component';
import { SetUserLocationComponent } from './extra/modals/set-user-location/set-user-location.component';
import { ToastsService } from './services/helpers/toasts.service';

import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { OfferAlertComponent } from './extra/modals/offer-alert/offer-alert.component';
import { ModalManagerService } from './services/misc/modal-manager.service';
import { CelebrationAnimationService } from './services/misc/celebration-animation.service';


declare let window: any;
declare var UXCam: any;



register()

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {

  latestVersion = '';
  versionNumber: any;
  isReady: boolean = false;

  internetSubscription: Subscription = new Subscription;

  constructor(
    private platform: Platform,
    private router: Router,
    private navCtrl: NavController,
    private alertController: AlertController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private modalController: ModalController,
    private appVersion: AppVersion,
    private homeService: HomeService,
    private popoverController: PopoverController,
    private translate: TranslateService,
    private authService: AuthService,
    private utilityService: UtilityService,
    private network: Network,
    private analyticsService: GoogleAnalyticsService,
    private notificationService: NotificationsService,
    private branch: BranchIo,
    private mixpanelService: MixedPanelsService,
    private locationPermissionService: LocationPermissionService,
    private locationService: LocationService,
    private facebookSdk: Facebook,
    private toastService: ToastsService,
    private modalManagerService: ModalManagerService,
    private _s: CelebrationAnimationService
  ) {
    this.platform.ready().then(() => {
      this.isReady = true;
      this.savePlatformType();
      this.isPlatformCordova();
      this.checkNetworkStatus();
      this.subscribeToDisconnect();
      // splashScreen.show();
      const lang = this.authService.appLanguage;
      this.authService.appLanguage = lang;
      this.translate.setDefaultLang(authService.appLanguage);
      this.initializeApp();
      this.utilityService.disableConsoleInProduction();
      // this.notificationService.checkAndRequestNotificationPermissions();
      // analyticsService.startTrackerWithId('G-4C29FD1CWE')
      this.checkForPermissions()
      this.handleBranch();
      this.mixpanelService.initializeMixpanel();
      this.loadUxCam();
      this.initializeFacebook();

    })
    this.analyticsService.loadGoogleAnalytics();

    platform.resume.subscribe(() => {
      this.handleBranch();
    });

    // this.setUserLocation();
    // this.checkForOffers();
  }

  checkForOffers() {
    const isOffer: boolean = true;
    const offerData: any =  {
      "title": "Exclusive Offer Just for You!",
      "description": "Enjoy 20% off on your next purchase. Use code SAVE20 at checkout.",
      "logoUrl": "/assets/img/daykart/clock.png",
      "isAnimatedLogo": false,
      "showButton": true,
      "buttonText": "Claim Offer",
      "navigationType": "page",
      "navigationData": {
        "pageUrl": "account/wallet",
        "queryParams": {
          "returnUrl": "/"
        }
      }
    };
    if (isOffer) {
      this.modalManagerService.openOfferPopUpModal(offerData)
    }
  }

  initializeFacebook() {
    this.facebookSdk.getApplicationId().then((res: any) => {
      console.log('facebook app id:', res);
    }).catch(error => {
      console.error('Error getting fb id:', error);
    });

    this.facebookSdk.getApplicationName().then((res: any) => {
      console.log('facebook app name:', res);
    }).catch(error => {
      console.error('Error getting fb name:', error);
    });
  }

  async checkForPermissions() {
    const isLocationAllowed = await this.locationPermissionService.checkIfLocationAllowed();
    const isLocationAlreadySet = this.locationService.coordinate.getValue() !== null;

    if (isLocationAllowed || isLocationAlreadySet) {
      //request for notification permission only and setup fcm token
      console.log("location allowed in app component so setting up notification receiver")
      await this.notificationService.checkAndRequestNotificationPermissions2();
    }
    else {
      console.log("location not allowed in app component");
      this.setUserLocation();
      // this.askForPermissions();
    }
  }

  // async askForPermissions() {
  //   try {
  //     const locationGranted = await this.askForLocation();
  //     // alert("location granted"+locationGranted)
  //     if (locationGranted) {
  //       console.log("Location permission granted by user");
  //       await this.notificationService.checkAndRequestNotificationPermissions2();
  //       console.log("Notification permission granted");
  //     }
  //     else {
  //       console.log("location permission not granted by user");
  //       await this.handleLocationPermissionDenied();

  //     }
  //   } catch (error) {
  //     console.log("got error in permission granted by user")

  //     console.error('Error requesting permissions', error);
  //   }
  // }

  async askForPermissions() {
    try {
      const locationGranted = await this.askForLocation();
      if (locationGranted) {
        //to call notification receiver
        console.log("Location permission granted");
        await this.notificationService.checkAndRequestNotificationPermissions2();
        console.log("Notification permission granted");
      }
      else {
        console.log("location permission not granted by user");
        const alert = await this.alertController.getTop();
        const modal = await this.modalController.getTop();
        console.log("modal", modal, "alert", alert)
        if (alert) {
          console.log("alert found open")
        }
        else {
          console.log("alert not found open")

          await this.handleLocationPermissionDenied();
        }
        // await this.handleLocationPermissionDenied();

      }
    } catch (error) {
      console.log("got error in permission granted by user")

      console.error('Error requesting permissions', error);
    }
  }



  async askForLocation(): Promise<boolean> {
    try {
      const permissionGranted: any = await this.locationPermissionService.askForLocationPermission();
      if (permissionGranted) {
        const locationServicesEnabled = await this.locationPermissionService.turnOnLocationServices();
        if (locationServicesEnabled) {
          const currentLocation: any = await this.locationPermissionService.findMyCurrentLocation();
          this.locationService.setLocation(currentLocation)
          return permissionGranted;
        }
        else {
          // this.handleLocationServicesNotEnabled();
          return false;
        }

        // const currentLocation: any = await this.locationPermissionService.findMyCurrentLocation();
        // this.locationService.setLocation(currentLocation)
        // return permissionGranted;
      }
      else {
        return false;
      }
    } catch (error) {
      console.error('Error requesting location permission', error);
      return false;
    }
  }

  async handleLocationPermissionDenied() {
    // alert("called modal")
    const modal = await this.modalController.create({
      component: LocationPermissionModalComponent,
      backdropDismiss: false,
      cssClass: "location-not-allowed-modal",
      initialBreakpoint: .9,
      breakpoints: [.9],
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();

    if (data === 'requestPermission') {
      this.askForPermissions();
    }
    else if (data === 'openSettings') {
      this.askForPermissions();
    }
    else if (data === 'granted') {
      this.askForPermissions();
    }
  }

  async handleLocationServicesNotEnabled() {
    const alert = await this.alertController.create({
      header: 'Enable Location Services',
      message: 'Please enable location services for the app to work correctly.',
      buttons: [
        {
          text: 'Open Settings',
          handler: () => {
            this.locationPermissionService.openLocationSettings()
          }
        }
      ],
      backdropDismiss: false // Prevents the user from dismissing the alert
    });

    await alert.present();
  }

  loadUxCam() {
    // UXCam.optIntoSchematicRecordings() //To enable session video recording on iOS 

    const configuration = {
      userAppKey: 'eg9s3kkgbtj1p2n',
      enableAutomaticScreenNameTagging: false,
      enableImprovedScreenCapture: true,
    }

    UXCam.startWithConfiguration(configuration);
  }

  // Branch initialization
  handleBranch = () => {
    // only on devices
    if (!this.platform.is('cordova')) { return }
    // const Branch = window['Branch'];
    this.branch.initSession().then((data: any) => {
      if (data['+clicked_branch_link']) {
        // read deep link data on click
        console.log('Deep Link Data: ' + JSON.stringify(data));
        this.notificationService.handleNavigation(data);
      }
    });
  }

  checkNetworkStatus() {
    if (this.network.type === 'none') {
      this.router.navigate(['no-internet']);
    }
  }

  subscribeToDisconnect() {
    const isCordova = localStorage.getItem('isCordova');
    if (isCordova === 'native') {
      this.network.onDisconnect().subscribe(() => {
        this.router.navigate(['no-internet']);
      },
        (error) => {
          alert(error)
        }
      );
    }
  }

  async savePlatformType() {
    // await this.platform.ready();

    if (this.platform.is('android')) {
      localStorage.setItem('platform', 'android');
    } else if (this.platform.is('ios')) {
      localStorage.setItem('platform', 'iOS');
    } else if (this.platform.is('mobileweb')) {
      localStorage.setItem('platform', 'mobileweb');
    }
    else if (this.platform.is('desktop')) {
      localStorage.setItem('platform', 'browser');
    }
    else {
      localStorage.setItem('platform', 'web');
    }
  }



  isPlatformCordova() {
    if (window.cordova && window.cordova.platformId === 'browser') {
      localStorage.setItem('isCordova', 'cordovaBrowser')
      // alert('Running in Cordova browser environment');
    } else if (window.cordova) {
      // alert('Running in native app environment');
      localStorage.setItem('isCordova', 'native')
    } else {
      localStorage.setItem('isCordova', 'other')
      // alert('Not running in Cordova environment');
    }
  }

  ngOnInit(): void {
    this.splashScreen.show();
    this.backButtonEvent()
  }

  switchLanguage(lang: string) {
    this.translate.use(lang);
  }

  initializeApp() {
    // this.platform.ready().then(() => {
    //   if (this.platform.is("cordova")) {
    //     // this.statusBar.show();
    //     // this.statusBar.styleDefault();
    //     // this.splashScreen.hide();
    //   }
    //   if (this.platform.is('android')) {
    //     this.checkAppVersion();
    //   }
    // });

    const isCordova = localStorage.getItem('isCordova');
    if (isCordova === 'native') {
      this.checkAppVersion();
    }
  }

  latestPlayStoreVersion() {
    const version = this.latestVersion;
    return (version);
  }

  async checkAppVersion() {
    const version = await this.appVersion.getVersionNumber();
    this.versionNumber = (version);
    this.checkVersion();
  }

  checkVersion() {
    this.homeService.currentVersion({ version: this.versionNumber }).subscribe((res: any) => {
      if (res.success) {
        this.latestVersion = res?.latestVersion;
        const isUpdateAvailable = this.compareVersions(this.versionNumber, this.latestVersion)
        if (isUpdateAvailable) {
          this.openAppUpdateModal(res?.link);
          // this.presentAlert(res?.link);
        }
        else {
          console.log('Already up-to-date');
        }
      }
    }, (error) => {
      console.log(error);
    });
  }

  compareVersions(currentVersion: string, latestVersion: string): boolean {
    const currentVersionParts = currentVersion.split('.');
    const latestVersionParts = latestVersion.split('.');

    for (let i = 0; i < currentVersionParts.length; i++) {
      if (parseInt(currentVersionParts[i]) < parseInt(latestVersionParts[i])) {
        return true; // Update available
      }
    }

    return false; // No update available
  }

  // ----------------------------------------App update alert---------------------------------------

  async presentAlert(link: any) {
    const alert = await this.alertController.create({
      header: 'New Update!',
      message: 'Please update the app!',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Update',
          role: 'confirm',
          handler: () => {
            window.location.href = link;
            (navigator as any).app.exitApp();
          },
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }

  async openAppUpdateModal(link: any) {
    let modal = await this.modalController.create({
      component: AppUpdateComponent,
      cssClass: 'app-update-modal',
      backdropDismiss: false,
      keyboardClose: false,
      componentProps: {
        playStoreLink: link
      },
    })

    await modal.present();

    const { data } = await modal.onDidDismiss();
  }

  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(9999, async () => {

      const modal = await this.modalController.getTop();
      const alert = await this.alertController.getTop();
      const popover = await this.popoverController.getTop();

      if (popover) {
        popover.dismiss();
      }
      else if (alert) {
        alert.dismiss()
      }
      // else if (modal) {
      //   modal.dismiss();
      // }
      else if (modal) {
        // Check if the modal is the update modal
        if (modal.component === AppUpdateComponent) {
          // Skip dismissal for the update modal
        }
        else if (modal.component === LocationPermissionModalComponent) {
          // Skip dismissal for the update modal
          const isLocationAllowed = await this.locationPermissionService.checkIfLocationAllowed();

          if (isLocationAllowed) {
            modal.dismiss();
          }
          else {
            //do nothing if location not allowed
          }
        }
        else if (modal.component === SetUserLocationComponent) {
          // Skip dismissal for the update modal
          const isLocationAllowed = await this.locationPermissionService.checkIfLocationAllowed();
          const isLocationAlreadySet = this.locationService.coordinate.getValue() !== null;

          if (isLocationAllowed || isLocationAlreadySet) {
            modal.dismiss();
          }
          else {
            this.toastService.showToast("Please select a location first")
            //do nothing if location not allowed
          }
        }
        else {
          modal.dismiss();
        }
      }
      else {
        if (this.router.url === '/') {
          const shouldExitApp = await this.showExitConfirmation();
          if (shouldExitApp) {
            (navigator as any).app.exitApp();
          }
        } else {
          this.navCtrl.back();
        }
      }
    });
  }

  async showExitConfirmation(): Promise<boolean> {
    const alert = await this.alertController.create({
      header: 'Confirm Exit',
      message: 'Do you want to exit the app?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            return false;
          }
        },
        {
          text: 'Yes',
          handler: () => {
            (navigator as any).app.exitApp();
            return true;
          }
        }
      ]
    });

    await alert.present();
    const result = await alert.onDidDismiss();
    return result.role === 'yes';
  }

  ngOnDestroy() {
    this.internetSubscription.unsubscribe();
  }


  // -----------------------------Set user location on app start-------------------

  setUserLocation() {
    this.locationSelectModal();
  }

  async locationSelectModal() {
    const modal = await this.modalController.create({
      component: SetUserLocationComponent,
      backdropDismiss: false,
      cssClass: 'set-user-location',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      keyboardClose: false,
      componentProps: {
        sectionType: 'search'
      },
      canDismiss: (data?: any, role?: string) => {
        return new Promise<boolean>(resolve => resolve(role === "close" || role === 'backdrop'))
      }
    });
    return await modal.present();
  }
}
