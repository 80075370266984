import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';

// components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
// import { ProductCardComponent } from './layout/cards/product-card/product-card.component';
import { DaykartPolicyComponent } from './public/daykart-policy/daykart-policy.component';
import { LoadingSpinnerComponent } from './extra/loading-spinner/loading-spinner.component';
import { PopoverMenuComponent } from './layout/popover-menu/popover-menu.component';
import { AddressComponent } from './shared/account/address/address.component';
import { AboutusComponent } from './shared/account/aboutus/aboutus.component';
import { HomeComponent } from './shared/home/home.component';
import { CategoryListComponent } from './shared/home/category-list/category-list.component';
import { CartComponent } from './shared/cart/cart.component';
import { OrderSummaryComponent } from './shared/order-summary/order-summary.component';
import { ProductCardComponent } from './layout/cards/product-card/product-card.component';
import { AddAddressComponent } from './shared/account/address/add-address/add-address.component';
import { AddressSummaryComponent } from './shared/account/address/address-summary/address-summary.component';
import { StoreNotFoundComponent } from './shared/home/store-not-found/store-not-found.component';
import { HeaderComponent } from './layout/header/header.component';
import { ProductDetailComponent } from './layout/product-detail/product-detail.component';
import { FooterComponent } from './layout/footer/footer.component';
import { CardListComponent } from './layout/card-list/card-list.component';
import { AddressModalComponent } from './layout/address-modal/address-modal.component';
import { AddressFooterComponent } from './layout/address-footer/address-footer.component';
import { PaymentComponent } from './shared/cart/payment/payment.component';
import { AccountComponent } from './shared/account/account.component';
import { WalletComponent } from './shared/account/wallet/wallet.component';
import { AddressBookComponent } from './shared/account/address-book/address-book.component';
import { OrderListComponent } from './shared/account/order-list/order-list.component';
import { SearchProductComponent } from './shared/home/search-product/search-product.component';
import { OrderDetailsComponent } from './shared/account/order-list/order-details/order-details.component';
import { OrderTrackingComponent } from './shared/account/order-list/order-tracking/order-tracking.component';
import { FloatingCategoryComponent } from './layout/floating-category/floating-category.component';
import { CategoryModalComponent } from './layout/floating-category/category-modal/category-modal.component';
import { MapComponent } from './shared/account/address/map/map.component';
import { SwitchLanguageComponent } from './layout/switch-language/switch-language.component';
import { DesktopFooterComponent } from './layout/DesktopView/desktop-footer/desktop-footer.component';
import { PrivacyPolicyComponent } from './shared/account/aboutus/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './shared/account/aboutus/terms-conditions/terms-conditions.component';
import { HeaderSkeletonComponent } from './layout/mobileViewSekeleton/header-skeleton/header-skeleton.component';
import { HomeSkeletonComponent } from './layout/mobileViewSekeleton/home-skeleton/home-skeleton.component';
import { CategorylistSkeletonComponent } from './layout/mobileViewSekeleton/categorylist-skeleton/categorylist-skeleton.component';
import { CartSkeletonComponent } from './layout/mobileViewSekeleton/cart-skeleton/cart-skeleton.component';
import { ProductdetailsSkeletonComponent } from './layout/mobileViewSekeleton/productdetails-skeleton/productdetails-skeleton.component';
import { SearchproductSkeletonComponent } from './layout/mobileViewSekeleton/searchproduct-skeleton/searchproduct-skeleton.component';
import { SubCategorySkeletonComponent } from './layout/mobileViewSekeleton/categorylist-skeleton/sub-category-skeleton/sub-category-skeleton.component';
import { RequestProductComponent } from './layout/request-product/request-product.component';
import { TextTrimPipe } from './extra/pipes/text-trim.pipe';

// Services
import { TokenInterceptorService } from './services/helpers/token-interceptor.service';
import { LoadingInterceptorService } from './services/helpers/loading-interceptor.service';
import { LocationService } from './services/location.service';

// providers
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx'
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { SmsRetriever } from '@awesome-cordova-plugins/sms-retriever/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { BranchIo } from '@awesome-cordova-plugins/branch-io/ngx';



import { MaskitoDirective } from '@maskito/angular';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NoInternetPageComponent } from './extra/no-internet-page/no-internet-page.component';
import { SortModalComponent } from './extra/modals/sort-modal/sort-modal.component';
import { FilterModalComponent } from './extra/modals/filter-modal/filter-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { StoreBusinessHoursComponent } from './extra/modals/store-business-hours/store-business-hours.component';
import { StoreHelplineHoursComponent } from './extra/modals/store-helpline-hours/store-helpline-hours.component';
import { DaykartTermsConditionComponent } from './public/daykart-terms-condition/daykart-terms-condition.component';
import { DaykartRefundPolicyComponent } from './public/daykart-refund-policy/daykart-refund-policy.component';
import { DaykartReturnPolicyComponent } from './public/daykart-return-policy/daykart-return-policy.component';
import { DaykartShippingPolicyComponent } from './public/daykart-shipping-policy/daykart-shipping-policy.component';
import { AppUpdateComponent } from './extra/modals/app-update/app-update.component';
import { PageNotFoundComponent } from './extra/page-not-found/page-not-found.component';
import { InfoPopoverComponent } from './extra/info-popover/info-popover.component';
import { TitleHeaderComponent } from './layout/title-header/title-header.component';
import { ProductListComponent } from './shared/home/product-list/product-list.component';
import { CategoryCardComponent } from './layout/cards/category-card/category-card.component';
import { ProductNotFoundComponent } from './extra/product-not-found/product-not-found.component';
import { BuyAtStoreComponent } from './extra/modals/buy-at-store/buy-at-store.component';

import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import {OutlineButtonComponent} from './subscription/shared/components/buttons/outline-button/outline-button.component';
import {SubscriptionService} from './subscription/services/subscription.service';

import { Mixpanel } from '@awesome-cordova-plugins/mixpanel/ngx';
import { CustomerSupportComponent } from './extra/modals/customer-support/customer-support.component';
import { SupportIconComponent } from './layout/support-icon/support-icon.component';
import { ModalHeaderComponent } from './extra/modals/modal-header/modal-header.component';
import { LoaderComponent } from './extra/loader/loader.component';
import { LocationPermissionModalComponent } from './extra/modals/location-permission-modal/location-permission-modal.component';
import { OrderCompletionStatusComponent } from './layout/order-completion-status/order-completion-status.component';
import { OrderStatusTrackComponent } from './layout/order-status-track/order-status-track.component';
import { SetUserLocationComponent } from './extra/modals/set-user-location/set-user-location.component';

import { Facebook } from '@awesome-cordova-plugins/facebook/ngx'
import { OfferAlertComponent } from './extra/modals/offer-alert/offer-alert.component';
import { OutOfStockPopupComponent } from './extra/modals/out-of-stock-popup/out-of-stock-popup.component';
import { CelebrationSectionComponent } from './layout/celebration-section/celebration-section.component';

import { CFPaymentGateway } from '@awesome-cordova-plugins/cashfree-pg/ngx'
import { LocationSetupComponent } from './shared/home/location-setup/location-setup.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    // HomeComponent,
    LoginComponent,
    LoadingSpinnerComponent,
    PopoverMenuComponent,
    DaykartPolicyComponent,
    // ProductCardComponent
    // AddressComponent
    AddressComponent,
    AboutusComponent,
    HomeComponent,
    ProductListComponent,
    CategoryListComponent,
    CartComponent,
    ProductCardComponent,
    CategoryCardComponent,
    AddAddressComponent,
    AddressSummaryComponent,
    OrderSummaryComponent,
    HeaderComponent,
    FooterComponent,
    ProductDetailComponent,
    CardListComponent,
    AddressModalComponent,
    AddressFooterComponent,
    StoreNotFoundComponent,
    PaymentComponent,
    AccountComponent,
    WalletComponent,
    AddressBookComponent,
    OrderListComponent,
    OrderDetailsComponent,
    SearchProductComponent,
    OrderTrackingComponent,
    FloatingCategoryComponent,
    CategoryModalComponent,
    RequestProductComponent,
    TextTrimPipe,
    TextTrimPipe,
    MapComponent,
    SwitchLanguageComponent,
    DesktopFooterComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    HeaderSkeletonComponent,
    HomeSkeletonComponent,
    CategorylistSkeletonComponent,
    CartSkeletonComponent,
    ProductdetailsSkeletonComponent,
    SearchproductSkeletonComponent,
    SubCategorySkeletonComponent,
    NoInternetPageComponent,
    SortModalComponent,
    FilterModalComponent,
    StoreBusinessHoursComponent,
    StoreHelplineHoursComponent,
    DaykartTermsConditionComponent,
    DaykartRefundPolicyComponent,
    DaykartReturnPolicyComponent,
    DaykartShippingPolicyComponent,
    AppUpdateComponent,
    PageNotFoundComponent,
    InfoPopoverComponent,
    TitleHeaderComponent,
    ProductNotFoundComponent,
    BuyAtStoreComponent,
    CustomerSupportComponent,
    SupportIconComponent,
    ModalHeaderComponent,
    LoaderComponent,
    OrderCompletionStatusComponent,
    SetUserLocationComponent,
    LocationPermissionModalComponent,
    OrderStatusTrackComponent,
    OfferAlertComponent,
    OutOfStockPopupComponent,
    CelebrationSectionComponent,
    LocationSetupComponent
    
  ],
  imports: [
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaskitoDirective,
    IonicStorageModule.forRoot({
      name: '__daykartdb',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CommonModule,
    BrowserAnimationsModule,
    OutlineButtonComponent,
    // IonicModule,
    // HttpClientModule,
    // FormsModule,
    // ReactiveFormsModule,
    // TranslateModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    // Uncomment to implement loading spinner
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LoadingInterceptorService,
    //   multi: true
    // },
    Geolocation,
    LocationAccuracy,
    NativeGeocoder,
    Diagnostic,
    SplashScreen,
    StatusBar,
    AppVersion,
    // Geolocation,
    NativeGeocoder,
    Diagnostic,
    InAppBrowser,
    LocationAccuracy,
    LocationService,
    Network,
    FirebaseX,
    BranchIo,
    Mixpanel,
    Facebook,
    CFPaymentGateway,
    SubscriptionService,//added by Gaurav
    ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {

}
