import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Router}                                                                from '@angular/router';
import {AlertController, ModalController}                                      from '@ionic/angular';
import {AuthService}                                                           from 'src/app/services/auth.service';
import {CartService}                                                           from 'src/app/services/cart.service';
import {ToastsService}                                                         from 'src/app/services/helpers/toasts.service';
import {ProductService}                                                        from 'src/app/services/product.service';
import Swiper                                                                  from 'swiper';
import {SubscriptionService}                                                   from '../../subscription/services/subscription.service';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { SearchService } from 'src/app/services/search.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit {
  @Input() productId: any;
  @Input() eventData:any;

  productData: any;
  productImages: any = [];
  similarProductData: any;
  isInCart: boolean = false;
  productQuantity: number = 1;
  isLoaded: boolean = false;

  constructor(public modalController: ModalController,
              private toastService: ToastsService,
              private productService: ProductService,
              private cartService: CartService,
              private authService: AuthService,
              private router: Router,
              private alertController: AlertController,
              private subscriptionService: SubscriptionService,
              private mixpanelService : MixedPanelsService,
              private searchService : SearchService
  ) {
  }

  ngOnInit() {
    this.productDetail();
    this.updateQuantity();
  }

  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;
  swiper?: Swiper;

  swiperSlideChangeded(e: any) {
  }

  swiperReady() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }

  @ViewChild('swiper')
  swiperRef2: ElementRef | undefined;
  swiper2?: Swiper;

  swiperSlideChangeded2(e: any) {
  }

  swiperReady2() {
    this.swiper2 = this.swiperRef2?.nativeElement.swiper2;
  }

  productDetail() {
    this.productService.productDetail(this.productId).subscribe((res: any) => {
      if (res?.success) {
        this.isLoaded = true;
        this.productData = res?.productInfo;
        //added by Gaurav for the subscription work
        if (!this.productData.isSubscription) {
          this.subscriptionService.checkIfProductIsInSubscription(this.productId).then((isIn: boolean) => {
            this.productData.isSubscription = isIn;//we need to show the link to the subs page
          });
        }

        this.sendViewEvent();
        
        this.productImages = res?.productInfo?.images;
        this.similarProductData = res?.productInfo?.similarProducts;
        this.mixpanelService.track('Product Viewed', {
          'product_name': this.productData?.name,
          'product_id': this.productData?.productId,
        });
        this.mixpanelService.track('s_product_details', {
          'product_name': this.productData?.name,
          'product_id': this.productData?.productId,
        });
      } else {
        this.isLoaded = false;
      }
    });
  }

  sendViewEvent(){
    let indexName = '';
    // Set the index name based on the product stock
    if (this.productData?.stock > 0) {
      indexName = environment.algoliaSearch.dbIndex
    } else {
      indexName = environment.algoliaSearch.inactiveProductIndex
    }
    
    this.searchService.sendViewEvents([this.productData?.productId],'viewed product detail', indexName)
  }

  loadDefaultImg(event: any) {
    this.productService.loadDefaultImg(event);
  }

  checkIfUserLoggedIn(id: any) {
    this.sendCartEvent(id);

    const isLoggedIn = this.authService.getToken();
    if (isLoggedIn) {
      this.addToCart(id);
    } else {
      this.showAlert();
    }
  }

  addToCart(id: string) {
    let payload = {
      productId: id,
      quantity: 1,
    };
    this.cartService.addtocart(payload).subscribe((res: any) => {
      if (res.success) {
        // this.cartService.cartProductList()
        this.cartService.reloadCartData();
      }
    });
  }

  updateQuantity() {
    this.cartService.getCartItems().subscribe(cartItems => {
      const productQuantity = this.cartService.getProductQuantity(this.productId);
      if (productQuantity === 0) {
        this.isInCart = false;
      } else {
        this.isInCart = true;
        this.productQuantity = productQuantity;
      }
    });
  }

  increaseProductQuantity(product: any) {
    if (this.productQuantity < product?.stock) {
      const payload = {
        productId: product.productId,
        quantity: this.productQuantity + 1,
      };
      this.cartService.updateCart(payload).subscribe((res: any) => {
        if (res.success) {
          this.cartService.reloadCartData();
          // this.cartService.cartProductList();
          // this.toastService.showToast(res?.message);
          this.productQuantity = this.productQuantity + 1;
        }
      });
    } else {
      this.toastService.showToast('Product is out of stock');
    }
  }

  decreaseProductQuantity(product: any) {
    if (this.productQuantity >= 1) {
      const payload = {
        productId: product.productId,
        quantity: this.productQuantity - 1,
      };
      this.cartService.updateCart(payload).subscribe((res: any) => {
        if (res.success) {
          this.cartService.reloadCartData();
          // this.cartService.cartProductList();
          if (this.productQuantity === 1) {
            this.isInCart = false;
          }
          this.productQuantity = this.productQuantity - 1;
          // this.toastService.showToast(res?.message)
        }
      });
    }
  }

  loadNewProduct(event: any) {
    this.isLoaded = false;
    if (event?.load) {
      this.productService.productDetail(event?.productId).subscribe((res: any) => {
        this.isLoaded = true;
        this.productId = res?.productInfo?.productId;
        this.productData = res?.productInfo;
        this.productImages = res?.productInfo?.images;
        this.similarProductData = res?.productInfo?.similarProducts;
        this.updateQuantity();
      });
    }
  }

  async showAlert() {
    const alert = await this.alertController.create({
      cssClass: 'custom-login-alert',
      header: 'Login Required',
      message: 'You need to log in to add products to your cart.',
      buttons: [
        {
          text: 'Keep browsing',
          role: 'cancel',
          cssClass: 'secondary-option',
          handler: () => {
          },
        }, {
          cssClass: 'primary-option',
          text: 'Proceed to login',
          handler: () => {
            const currentUrl = this.router.url;
            const navigationExtras = {
              state: {
                redirectUrl: currentUrl,
              },
            };
            this.modalController.dismiss();
            this.router.navigate(['login'], navigationExtras);
          },
        },
      ],
    });
    await alert.present();
  }

  async navigateToCart() {
    const modal = await this.modalController.getTop();
    if (modal) {
      modal.dismiss();
    }
    this.router.navigate(['cart']);
  }

  public goToSub(url: string): void {
    this.modalController.dismiss().then(() => {
      this.router.navigate([url]);
    });
  }


  // --------------------------------------_Algolia events-------------------------------------

  sendCartEvent(productId:string){
    const currentUrl = this.splitCurrentUrl();
    const index = this.findProductIndex();

    if(currentUrl === 'search'){
      if(this.eventData){
        this.searchService.sendAddToCartEventAfterSearch([productId],'searched product added to cart', this.eventData?.queryId ,[this.eventData?.position],index);
      }
      else{
        this.searchService.sendAddToCartEventAfterSearch([productId],'searched product added to cart','',[],index);
      }
    }
    else{
      this.searchService.sendGenericAddToCartEvent([productId], 'product added to cart', index)
    }
  }

  splitCurrentUrl(){
    const currentUrl = this.router.url;
    const path = currentUrl.split('?')[0];
    const urlParts = path.split('/');

    return urlParts[1];
  }

  findProductIndex(){
    let indexName = '';

    if (this.productData?.stock > 0) {
      indexName = environment.algoliaSearch.dbIndex
    } else {
      indexName = environment.algoliaSearch.inactiveProductIndex
    }
    return indexName;
  }
}
