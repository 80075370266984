<!-- <app-floating-category></app-floating-category> -->
<!-- ---------------footer--item------------ -->
<div
  class="add-item"
  *ngIf="cartCount > 0 && authService.checkIfUserLoggedIn()"
>
  <ion-grid>
    <ion-row>
      <ion-col size="2" sizeMd="1" sizeLg="1">
        <ion-img
          src="{{ imageForDisplay }}"
          (ionError)="loadDefaultImg($event)"
        />
      </ion-col>
      <ion-col
        size="5"
        (click)="openCartModal()"
        class="pointer ion-activatable ripple-parent rounded-rectangle"
      >
        <h5>
          <b
            >{{ cartCount }}
            {{ cartCount === 1 ? ("item" | translate) : ("items" | translate) }}
            <ion-icon name="chevron-up-outline"></ion-icon
          ></b>
        </h5>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-col>

      <ion-col size="5">
        <ion-button expand="block" class="common-btn" (click)="navigateToCart()"
          >{{ "buy" | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
