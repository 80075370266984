import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, Platform, ViewWillEnter } from '@ionic/angular';
import { CartService } from 'src/app/services/cart.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';
import { OrderService } from 'src/app/services/order.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { LoadingControllerService } from 'src/app/services/helpers/loading-controller.service';

import { CFPaymentGateway, CFSession, CFDropCheckoutPayment, CFThemeBuilder, CFPaymentComponentBuilder, CFEnvironment, CFPaymentModes } from '@awesome-cordova-plugins/cashfree-pg/ngx';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';


declare var cordova: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})

export class PaymentComponent implements OnInit, ViewWillEnter {
  ref: any;
  iabRef: any;
  url: any;

  selectedAddress: any;
  billData: any;
  selectedOption!: string;


  defaultPaymentOption: any;

  windowRef: Window | any = null;

  orderTotal!: number;
  cartData: any;

  // walletAmount: number = 100;
  // billAmount: number = 200;

  // paymentOptions = [
  //   { label: 'Cash', value: 'online', description: 'Pay Online Payment', "isDefault": true,"isDisabled": false },
  //   { label: 'Online', value: 'cod', description: 'Cash on Delivery', "isDefault": false ,"isDisabled": false},
  //   { label: 'Wallet', value: 'wallet', description: 'Pay by Wallet', "isDefault": false,"isDisabled": false },
  // ];

  paymentOptions = [
    { label: 'Cash', value: 'online', description: 'Pay Online Payment', "isDefault": true },
    { label: 'Online', value: 'cod', description: 'Cash on Delivery', "isDefault": false },
    // { label: 'Wallet', value: 'wallet', description: 'Pay by Wallet', "isDefault": false },
  ];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private toastService: ToastsService,
    private cartService: CartService,
    private translateService: TranslateService,
    private mixpanelService: MixedPanelsService,
    private loadingService: LoadingControllerService,
    private cashfree: CFPaymentGateway,
    private platform: Platform,
    private http: HttpClient
  ) {
  }


  ngOnInit() {
    this.cartService.cartSubject$.subscribe((data: any) => {
      console.log(data)
      this.cartData = data
      if (data?.success) {
        this.orderTotal = data?.billDetails?.grandTotal;
      }
      else {
        // this.toastService.showToast('Add items to cart first')
        // this.router.navigate(['cart'])
      }
    });


    this.activatedRoute.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation()?.extras.state) {
        const orderData = this.router.getCurrentNavigation()?.extras.state?.['data'];
        this.selectedAddress = orderData?.selectedAddress
        this.billData = orderData?.billData;
      }
      else {

      }
    });

    this.mixpanelService.track("s_payment", {})
  }

  ionViewWillEnter() {
    // this.setPaymentOptions();
    this.setDefaultPayment();
  }


  onOptionChange(value: string) {
    this.selectedOption = value;
    console.log(this.selectedOption)
    this.mixpanelService.track(this.selectedOption === "online" ? "b_online_payment" : "b_cash_on_delivery", { "screenName": "Payment", "orderValue": this.orderTotal });
  }

  // changePaymentOption(index: any) {
  //   for (let i = 0; i < this.paymentOptions.length; i++) {
  //     if (i === index) {
  //       this.defaultPaymentOption = this.paymentOptions[i].value;
  //       this.paymentOptions[i].isChecked = true;
  //     }
  //     else {
  //       this.paymentOptions[i].isChecked = false;
  //     }
  //   }

  // }

  // setPaymentOptions() {
  //   if (this.walletAmount < this.billAmount) {
  //     // Hide the "Wallet" option
  //     this.paymentOptions = this.paymentOptions.filter(option => option.value !== 'wallet');

  //     const defaultOption = this.paymentOptions.find(option => option.isDefault);
  //     if (defaultOption) {
  //       this.selectedOption = defaultOption.value;
  //     }
  //   } else {
  //     // Make the "Wallet" option the only selected option and disable other options
  //     this.selectedOption = 'wallet';
  //     this.paymentOptions.forEach((option: any) => {
  //       if (option.value !== 'wallet') {
  //         option.isDisabled = true;
  //       }
  //     });
  //   }
  // }

  setDefaultPayment() {
    const defaultOption = this.paymentOptions.find(option => option.isDefault);
    if (defaultOption) {
      this.selectedOption = defaultOption.value;
      this.mixpanelService.track(defaultOption.value === "online" ? "b_online_payment" : "b_cash_on_delivery", { "screenName": "Payment", "orderValue": this.orderTotal });
    }

  }

  orderNow() {
    this.mixpanelService.track("Payment Type Chosen", {
      'payment_method': this.selectedOption
    });

    this.mixpanelService.track("b_confirm_payment", { "screenName": "Payment", "orderValue": this.orderTotal });

    if (this.selectedOption === 'cod') {
      this.payOnDelivery();
    }
    else if (this.selectedOption === 'online') {
      this.payOnline();
    }
  }

  async payOnDelivery() {
    this.loadingService.showLoading("Please wait while we place your order...", 'crescent')

    const payload = {
      shippingAddressId: this.selectedAddress?._id,
      paymentMethod: this.selectedOption
    }
    this.orderService.placeOrder(payload).subscribe((res: any) => {
      if (res.success) {
        this.orderService.updateActiveOrders();
        this.toastService.showToast(res?.message, 1500, "-8rem");
        // this.cartService.cartProductList();
        this.mixpanelService.track('Order Completed', {
          'total_items': this.cartData?.cartProducts.length,
          'total_price': this.orderTotal,
          'payment_method': this.selectedOption,
        });

        this.cartService.reloadCartData();
        this.loadingService.dismissLoading();

        this.router.navigate(['order-status'], {
          relativeTo: this.activatedRoute,
          queryParams: {
            orderStatus: 'success',
            orderTime: res?.orderDetails?.createdAt,
            userOrderId: res?.orderDetails?.orderID,
            orderId: res?.orderId
          }
        });

        // this.router.navigate([`/account/order-list/order-track`], { queryParams: { orderId: res?.orderId } })
        history.replaceState({}, '', '/');
      }
      else {
        this.toastService.showToast(res?.message, 1500, "-8rem");
        this.router.navigate(['order-status'], {
          relativeTo: this.activatedRoute,
          queryParams: {
            orderStatus: 'failure',
            orderTime: res?.orderDetails?.createdAt,
            userOrderId: res?.orderDetails?.orderID,
            orderId: res?.orderId
          }
        });
        this.loadingService.dismissLoading()
        this.toastService.showToast(res?.message, 1500, "-8rem");

      }
    },
      (error) => {
        this.loadingService.dismissLoading();
        this.toastService.showToast(error?.error?.message, 1500, "-8rem");
      }
    )


  }

  payOnline() {
    const isCordova = localStorage.getItem('isCordova');
    if (isCordova === 'native') {
      // for phonePe payment
      // this.payOnlineInApp();  

      // for cashfree payment
      this.payOnlineWithCashfree()
    }
    else {
      // this.payOnlineInBrowser2();
      this.payOnlineInBrowser2();
    }
  }

  payOnlineInBrowser2() {
    const payload = {
      shippingAddressId: this.selectedAddress?._id,
      paymentMethod: this.selectedOption
    };

    this.orderService.placeOrder(payload).subscribe((res: any) => {
      if (res.success) {
        if (res?.paymentType === 'wallet') {
          this.toastService.showToast(res?.message);
          this.cartService.reloadCartData();
          this.router.navigate([`/account/order-list/order-track`], { queryParams: { orderId: res?.orderId } })
          history.replaceState({}, '', '/');
        }
        else {
          const url = res.redirectUrl;
          localStorage.setItem('url', url);

          // Open the URL in a new window or tab using window.open()
          const windowRef: any = window.open(url, "_blank", "beforeload=no,location=no,clearcache=no,clearsessioncache=no");

          setTimeout(() => {
            if (windowRef !== null) {
              console.log('inside winref');
              windowRef.addEventListener('load', () => {
                console.log('inside load event');
                if (windowRef !== undefined) {
                  if (this.getBaseUrl(windowRef.location.href) === `${environment.apiUrl}/payment/success`) {
                    setTimeout(() => {
                      windowRef.close();
                      // this.cartService.cartProductList();
                      this.cartService.reloadCartData();

                      const urlParams = new URLSearchParams(windowRef.location.search);
                      const orderId = urlParams.get('orderId');

                      this.router.navigate([`/account/order-list/order-track`], { queryParams: { orderId: orderId } });
                      history.replaceState({}, '', '/');
                    }, 5000);
                  } else if (windowRef.location.href === `${environment.apiUrl}/payment/failure`) {
                    setTimeout(() => {
                      windowRef.close();
                    }, 5000);
                  }
                }
              });
            }
          }, 1000);
        }

      }
      else {
        this.toastService.showToast(res?.message)
      }
    });
  }

  openPaymentGateway() {
    const payload = {
      shippingAddressId: this.selectedAddress?._id,
      paymentMethod: this.selectedOption
    };

    this.orderService.placeOrder(payload).subscribe((res: any) => {
      if (res.status) {
        const target = "_blank";
        const options = "beforeload=no,location=no,clearcache=no,clearsessioncache=no";
        const paymentGatewayUrl = res.redirectUrl;

        // Open the payment gateway URL in a new window or tab
        this.windowRef = window.open(paymentGatewayUrl, target, options);

        // Listen for messages from the payment gateway window
        window.addEventListener('message', (event: any) => {
          console.log('inside message event', JSON.stringify(event))
          // Check the origin of the message to ensure it's from the expected domain
          if (event.origin !== 'https://7b5c-122-161-53-33.ngrok-free.app') return;

          // Process the message
          console.log('Received message:', event.data);

          // Close the payment gateway window
          // if (this.windowRef) {
          //   this.windowRef.close();
          // }

          // Perform additional actions based on the message content
          if (event.data === 'payment_success') {
            // Handle payment success
            console.log('Payment was successful.');
          } else if (event.data === 'payment_failure') {
            // Handle payment failure
            console.log('Payment failed.');
          }
        });
      }
    })
  }


  payOnlineInBrowser() {
    const payload = {
      shippingAddressId: this.selectedAddress?._id,
      paymentMethod: this.selectedOption
    }

    this.orderService.placeOrder(payload).subscribe((res: any) => {
      if (res.status) {
        var target = "_blank";
        const options = "beforeload=no,location=no,clearcache=no,clearsessioncache=no";
        this.url = res.redirectUrl;
        localStorage.setItem('url', this.url);

        // Open the URL in a new window or tab using window.open()
        this.windowRef = window.open(this.url, target, options);
        // localStorage.setItem('windowRef', JSON.stringify(this.windowRef));

        // Example of using window.onload event
        if (this.windowRef !== null) {
          console.log('inside windorRef')
          this.windowRef.onload = () => {

            console.log('window url', this.windowRef.location.href)

            if (this.windowRef != undefined) {
              if (this.getBaseUrl(this.windowRef.location.href) === `${environment.apiUrl}/payment/success`) {
                setTimeout(() => {
                  this.windowRef.close();
                  // this.cartService.cartProductList();
                  this.cartService.reloadCartData();

                  // Extract orderId from query parameters
                  const urlParams = new URLSearchParams(this.windowRef.location.search);
                  const orderId = urlParams.get('orderId');

                  this.router.navigate([`/account/order-list/order-track`], { queryParams: { orderId: orderId } });
                  history.replaceState({}, '', '/');
                }, 5000);
              } else if (this.windowRef.location.href === `${environment.apiUrl}/payment/failure`) {
                setTimeout(() => {
                  this.windowRef.close();
                }, 5000);
              }
            }
          };
        }
      }
      else {
        this.toastService.showToast(res?.message)
      }
    })
  }



  payOnlineInApp() {
    const payload = {
      shippingAddressId: this.selectedAddress?._id,
      paymentMethod: this.selectedOption
    }

    this.orderService.placeOrder(payload).subscribe((res: any) => {
      if (res.status) {
        if (res?.paymentType === 'wallet') {
          this.orderService.updateActiveOrders();
          this.toastService.showToast(res?.message);
          this.mixpanelService.track('Order Completed', {
            'total_items': this.cartData?.cartProducts.length,
            'total_price': this.orderTotal,
            'payment_method': this.selectedOption,
          });
          this.cartService.reloadCartData();
          this.router.navigate(['order-status'], {
            relativeTo: this.activatedRoute,
            queryParams: {
              orderStatus: 'success',
              orderTime: res?.orderDetails?.createdAt,
              userOrderId: res?.orderDetails?.orderID,
              orderId: res?.orderId
            }
          });
          // this.router.navigate([`/account/order-list/order-track`], { queryParams: { orderId: res?.orderId } })
          history.replaceState({}, '', '/');
        }
        else {
          var target = "_blank";
          const options = "beforeload=no,location=no,clearcache=no,clearsessioncache=no";
          this.url = res.redirectUrl
          localStorage.setItem('url', this.url)
          this.iabRef = cordova.InAppBrowser.open(this.url, target, options);
          localStorage.setItem('inAppBrowser', JSON.stringify(this.iabRef))
          this.iabRef.addEventListener("loadstop", (event: any) => {
            const url = localStorage.getItem('url');
            const iabRef = JSON.stringify(localStorage.getItem('inAppBrowser'))
            if (iabRef != undefined) {
              if (this.getBaseUrl(event.url) === `${environment.apiUrl}/payment/success`) {

                // if (event.url === `${environment.apiUrl}/payment/success`) {
                setTimeout(() => {
                  this.iabRef.close();
                  // this.cartService.cartProductList();
                  this.cartService.reloadCartData();
                  this.orderService.updateActiveOrders();

                  // Extract orderId from query parameters
                  const urlParams = new URLSearchParams(event.url.split('?')[1]);
                  const orderId = urlParams.get('orderId');

                  this.mixpanelService.track('Order Completed', {
                    'total_items': this.cartData?.cartProducts.length,
                    'total_price': this.orderTotal,
                    'payment_method': this.selectedOption,
                  });

                  this.router.navigate(['order-status'], {
                    relativeTo: this.activatedRoute,
                    queryParams: {
                      orderStatus: 'success',
                      orderTime: res?.orderDetails?.createdAt,
                      userOrderId: res?.orderDetails?.orderID,
                      orderId: res?.orderId
                    }
                  });

                  // this.router.navigate([`/account/order-list/order-track`], { queryParams: { orderId: orderId } })
                  history.replaceState({}, '', '/');

                }, 5000);
              }
              else if (event.url === `${environment.apiUrl}/payment/failure`) {
                setTimeout(() => {
                  this.iabRef.close();
                  this.router.navigate(['order-status'], {
                    relativeTo: this.activatedRoute,
                    queryParams: {
                      orderStatus: 'failure',
                      orderTime: res?.orderDetails?.createdAt,
                      userOrderId: res?.orderDetails?.orderID,
                      orderId: res?.orderId
                    }
                  });
                }, 5000);
              }
            }
          });
          this.toastService.showToast(res?.message);
        }

      }
      else {
        this.toastService.showToast(res?.message)
      }
    },
      (error) => {
        this.toastService.showToast(error?.error?.message);
      }
    )
  }

  getBaseUrl(url: string) {
    return url.split('?')[0];
  }

  getQueryParamValue(url: string, paramName: string) {
    const urlObj = new URL(url);
    return urlObj.searchParams.get(paramName);
  }

  goBackToCart() {
    const navigationExtras = {
      state: {
        addressData: this.selectedAddress
      }
    };
    this.router.navigate(['cart'], navigationExtras)
  }
  // ________________________________________cashfree payment_____________________________________

  payOnlineWithCashfree() {
    console.log("pay online with cashfree called")
    const payload = {
      shippingAddressId: this.selectedAddress?._id,
      paymentMethod: this.selectedOption
    }

    this.orderService.placeOrderWithCashfree(payload).subscribe(
      (res: any) => {
        if (res.success) {
          this.handleOrderSuccess(res);
        } else {
          this.toastService.showToast(res?.message);
        }
      },
      (error) => {
        this.toastService.showToast(error?.error?.message);
      }
    );
  }

  handleOrderSuccess(res: any) {
    if (res?.paymentType === 'wallet') {
      this.handleWalletPaymentSuccess(res);
    } else {
      this.handleCashfreePayment(res);
    }
  }

  handleWalletPaymentSuccess(res: any) {
    this.orderService.updateActiveOrders();
    this.toastService.showToast(res?.message, 1500, "-8rem");
    this.trackOrderCompletion();
    this.cartService.reloadCartData();
    this.navigateToOrderStatus('success', res);
    history.replaceState({}, '', '/');
  }

  handleCashfreePayment(res: any) {
    const orderId = res?.cashfreePayment?.order_id;
    const sessionId = res?.cashfreePayment?.payment_session_id;

    // Determine environment based on API response
    const environment = res?.cashfreePayment?.environment === 'production'
      ? CFEnvironment.PRODUCTION
      : CFEnvironment.SANDBOX;

    // Use these IDs and environment to initiate payment
    this.startPayment(orderId, sessionId, environment, res);
  }

  startPayment(orderID: any, sessionID: any, environment: CFEnvironment, res: any) {
    // Create a session object for sandbox testing
    const session = new CFSession(sessionID, orderID, environment);

    // Add supported payment modes using the enum
    const paymentComponent = new CFPaymentComponentBuilder()
      .add(CFPaymentModes.CARD)   // Allow payment via CARD
      .add(CFPaymentModes.UPI)    // Allow payment via UPI
      .build();

    // Optional: Set a custom theme for the payment gateway UI
    const theme = new CFThemeBuilder()
      .setNavigationBarBackgroundColor('#01b4bb')  // Set a theme color (optional)
      .setButtonBackgroundColor('#FF5722')         // Set button color
      .build();

    // Create the Drop Checkout payment object
    const dropPayment = new CFDropCheckoutPayment(session, paymentComponent, theme);

    // Initiate the payment
    this.cashfree.doDropPayment(dropPayment);


    // Set callback handlers for payment success or error
    this.cashfree.setCallback({
      onVerify: (cashfreeResult) => {
        console.log('Payment Success:', cashfreeResult);
        // Handle successful payment verification
        this.verifyPaymentStatus(res, cashfreeResult, "success")
      },
      onError: (error) => {
        console.log('Payment Error:', error);
        // Handle payment failure
        if (error?.code === "action_cancelled") {
          console.log("payment action cancelled");
        }
        else {
          this.verifyPaymentStatus(res, error, "failure")
        }
      }
    });
  }

  verifyPaymentStatus(res: any, paymentResponse: any, paymentStatus: any) {
    this.loadingService.showLoading("Verifying payment, Please wait...", 'crescent')

    const payload = {
      order_id: paymentResponse?.orderID,
      cashfree_status: paymentStatus,
      orderId: res?.orderId,
    }

    // Make an API call to your backend to verify the payment status
    this.orderService.verifyCashfreeOrder(payload).subscribe(
      (verificationResult: any) => {
        if (verificationResult?.success) {
          // If backend confirms success, handle success
          this.loadingService.dismissLoading();
          this.handlePaymentSuccess(res, paymentResponse);
        } else {
          // If backend confirms failure, handle failure
          this.loadingService.dismissLoading();
          this.handlePaymentFailure(res, paymentResponse);
        }
      },
      (error) => {
        // Handle error during verification (e.g., network issue)
        this.loadingService.dismissLoading();
        this.toastService.showToast('Error verifying payment. Please try again.');
      }
    );
  }

  handlePaymentSuccess(res: any, cashfreeCallback: any) {    
    // Log the error and show a toast
    this.toastService.showToast('Payment Successful! Thank you for your purchase.', 1500, "-8rem");
    
    // Perform order updates, tracking, and navigation
    this.cartService.reloadCartData();
    this.orderService.updateActiveOrders();
    this.mixpanelService.track('Order Completed', {
      'total_items': this.cartData?.cartProducts.length,
      'total_price': this.orderTotal,
      'payment_method': this.selectedOption,
    });

    this.router.navigate(['order-status'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        orderStatus: 'success',
        orderTime: res?.orderDetails?.createdAt,
        userOrderId: res?.orderDetails?.orderID,
        orderId: res?.orderId
      }
    });

    history.replaceState({}, '', '/');
  }

  handlePaymentFailure(res: any, cashfreeCallback: any) {
    // Log the error and show a toast
    this.toastService.showToast('Payment Failed. Please try again.', 1500, "-8rem");

    // Perform any failure-specific actions like navigation
    this.router.navigate(['order-status'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        orderStatus: 'failure',
        orderTime: res?.orderDetails?.createdAt,
        userOrderId: res?.orderDetails?.orderID,
        orderId: res?.orderId || null
      }
    });
  }

  handleInAppPaymentSuccess(event: any, res: any) {
    this.cartService.reloadCartData();
    this.orderService.updateActiveOrders();
    this.trackOrderCompletion();
    this.navigateToOrderStatus('success', res);
    history.replaceState({}, '', '/');
  }

  handleInAppPaymentFailure(res: any) {
    this.navigateToOrderStatus('failure', res);
  }

  trackOrderCompletion() {
    this.mixpanelService.track('Order Completed', {
      'total_items': this.cartData?.cartProducts.length,
      'total_price': this.orderTotal,
      'payment_method': this.selectedOption,
    });
  }

  navigateToOrderStatus(status: string, res: any) {
    this.router.navigate(['order-status'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        orderStatus: status,
        orderTime: res?.orderDetails?.createdAt,
        userOrderId: res?.orderDetails?.orderID,
        orderId: res?.orderId,
      },
    });
  }

  // initiatePayment() {
  //   this.getOrderDetails().subscribe(response => {
  //     const orderId = response.order_id;
  //     const sessionId = response.payment_session_id;

  //     console.log('Order ID:', orderId);
  //     console.log('Session ID:', sessionId);

  //     // Now, use these IDs to initiate payment
  //     this.startPayment(orderId, sessionId, {});
  //   }, error => {
  //     console.error('Error fetching order details:', error);
  //   });
  // }

  // getOrderDetails(): Observable<any> {
  //   // Mock response as per your API response
  //   const mockResponse = {
  //     "cart_details": null,
  //     "cf_order_id": "2183783394",
  //     "created_at": "2024-09-06T17:41:27+05:30",
  //     "customer_details": {
  //       "customer_id": "USER123",
  //       "customer_name": "joe",
  //       "customer_email": "mailto:joe.s@cashfree.com",
  //       "customer_phone": "+919876543210",
  //       "customer_uid": null
  //     },
  //     "entity": "order",
  //     "order_amount": 10.10,
  //     "order_currency": "INR",
  //     "order_expiry_time": "2024-10-06T17:41:27+05:30",
  //     "order_id": "order_100395072lhG2RQ3jOQQDbCFcel17Z8S3kU",
  //     "order_meta": {
  //       "return_url": "https://b8af79f41056.eu.ngrok.io?order_id=order_123",
  //       "notify_url": null,
  //       "payment_methods": null
  //     },
  //     "order_note": null,
  //     "order_splits": [],
  //     "order_status": "ACTIVE",
  //     "order_tags": null,
  //     "payment_session_id": "session_YInqW9RheStg0yOLBA35a_CwHJJwXvX2FMjLzwiysnsu2yYxMkhl4uPoux0brgAMm2_3UnufDm-y2r32EvlOVYxU2YKheODOaaBnTbFmiv2T",
  //     "terminal_data": null
  //   };

  //   return of(mockResponse); // Return the mock response as an observable
  // }


}
