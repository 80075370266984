import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonInfiniteScroll, ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { FilterModalComponent } from 'src/app/extra/modals/filter-modal/filter-modal.component';
import { SortModalComponent } from 'src/app/extra/modals/sort-modal/sort-modal.component';
import { ProductObject } from 'src/app/interface';
import { RequestProductComponent } from 'src/app/layout/request-product/request-product.component';
import { CategoryService } from 'src/app/services/category.service';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';
import { HomeService } from 'src/app/services/home.service';
import { ProductService } from 'src/app/services/product.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})

export class ProductListComponent implements OnInit, ViewWillEnter, ViewWillLeave {

  page: number = 1;
  limit: number = 12;
  totalPages: number = 0;
  loadingText: string = 'loading products...'
  @ViewChild(IonInfiniteScroll) infiniteScroll!: IonInfiniteScroll;
  public loaded = false;

  searchInput: any = '';
  dataFound: any = 0; // 0->default 1->no relevant data found 2->data found

  productsData: any = [];
  isLoaded: boolean = false;

  pageType: string = '';

  bannerId: string = '';
  sectionId: string = '';

  sortValue: string = ''
  sortOrder: string = '';
  selectedFilterOption: any = [];

  productsTitle!:string;

  categoryId: string = '';
  @ViewChild(IonContent) content!: IonContent;

  constructor(private productService: ProductService,
    private router: Router,
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private location: Location,
    private toastService: ToastsService,
    private categoryService: CategoryService,
    private mixpanelService: MixedPanelsService
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.pageType = params['pageType'];
    });
  }

  ngOnInit() {
    this.resetPagination()
    if (this.pageType === 'banner' || this.pageType === 'category') {
      this.activatedRoute.queryParams.subscribe((params: any) => {
        this.bannerId = params['bannerId'];
        this.sectionId = params['sectionId'];
        if (this.sectionId) {
          this.showSectionProducts()
        }
        else {
          this.toastService.showToast('No products found');
          this.router.navigate(['/'])
        }
      })
    }
    // let subscription = this.activatedRoute.queryParams.subscribe((params: any) => {
    //   const query = params['q'] || '';
    //   if (query) {
    //     this.searchInput = query;
    //     this.searchInProducts()
    //   }
    // })

    // subscription.unsubscribe();
    this.mixpanelService.track("s_product_list", {});
  }

  @ViewChild('searchBar', { read: ElementRef }) searchBar: ElementRef | undefined;

  ionViewWillEnter(): void {
    // this.setFocusOnSearchBar();
  }

  showSectionProducts() {
    this.homeService.sectionProductList(this.bannerId, this.sectionId, this.page, this.limit).subscribe((res: any) => {
      if (res?.success) {
        this.isLoaded = true;
        this.dataFound = 2;
        this.productsTitle = res?.title ? res?.title : 'Product List';
        // this.productsTitle = res?.title;
        this.productsData = res?.products;
        this.totalPages = res?.pages;
      }
      else {
        this.isLoaded = true;
        this.dataFound = 1;
      }
    })
  }

  refreshSearch(messageStatus: boolean) {
    if (messageStatus) {
      this.dataFound = 0;
    }
  }

  // searchInProducts() {
  //   if (this.searchInput === '' || this.searchInput.length === 1) {
  //     this.productsData = [];
  //     this.isLoaded = false;
  //     this.dataFound = 0;
  //     // this.setQueryToEmptyString();
  //     this.resetPagination();
  //     return;
  //   }
  //   else {
  //     this.dataFound = -1;
  //     this.isLoaded = false;
  //     this.productService.productSearch(this.searchInput, this.page, this.limit).subscribe((res: any) => {
  //       if (res.success) {
  //         this.isLoaded = true;
  //         this.dataFound = 2;
  //         this.productsData = res?.products;
  //         this.totalPages = res?.pages;
  //         this.setQueryToEmptyString();
  //       }
  //       else {
  //         this.isLoaded = true;
  //         this.dataFound = 1;
  //         this.productsData = [];
  //       }
  //     })
  //   }
  // }

  // onSearchInputChange() {
  //   this.resetPagination();
  // }

  paginate(event: any) {
    if (this.pageType === 'banner' || this.pageType === 'category') {
      this.loadSectionProducts(event)
    }    
  }

  loadSectionProducts(event: any) {
    if (this.page < this.totalPages) {
      this.page = this.page + 1;
      this.homeService.sectionProductList(this.bannerId, this.sectionId, this.page, this.limit).subscribe(
        (newData: any) => {
          this.productsData = this.productsData.concat(newData.products);
          event.target.complete();
        },
        (error) => {
          console.error('Error loading data:', error);
          event.target.complete();
        }
      );
    }
    else if (this.page === this.totalPages) {
      this.loadingText = 'No more content...'
      setTimeout(() => {
        event.target.disabled = true;
      }, 600);
    }
  }

  async requestProduct() {
    let modal = await this.modalController.create({
      component: RequestProductComponent,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      cssClass: 'request-product-modal',
      componentProps: {
        productName: this.searchInput
      },
      backdropDismiss: true,
    })

    modal.onDidDismiss().then((data) => {
      if (data?.data?.status) {
        this.dataFound = 0
      }
    })

    return await modal.present();
  }

  // setQueryToEmptyString() {
  //   this.router.navigate([], {
  //     relativeTo: this.activatedRoute,
  //     queryParams: { q: this.searchInput },
  //     queryParamsHandling: 'merge'
  //   });
  // }


  // setFocusOnSearchBar() {
  //   const searchInputElement = this.searchBar?.nativeElement.querySelector('input');
  //   if (searchInputElement) {
  //     searchInputElement.focus();
  //   }
  // }

  // reset() {
  //   this.resetPagination();
  //   this.searchInput = '';
  //   this.setQueryToEmptyString();
  //   // this.bannerProducts = [];
  //   this.isLoaded = false;
  //   this.dataFound = 0;
  // }

  resetPagination() {
    this.page = 1;
    this.limit = 12;
    this.totalPages = 0;
  }

  resetSort(){
    this.sortValue = '';
    this.sortOrder = '';
  }

  resetFilter(){
    this.selectedFilterOption = [];
  }

  ionViewWillLeave() {
    this.resetPagination();
    this.resetFilter();
    this.resetSort();
  }


}

