<!-- ----------------header---------------------- -->
<ion-header [translucent]="true" *ngIf="isLoaded">
  <div class="header-title">
    <ion-grid>
      <ion-row>
        <ion-col class="mid-center-content" size="1">
          <ion-img src="../../../assets/img/daykart/default-product-img.png" />
        </ion-col>
        <ion-col size="8">
          <p class="location">
            Delivery in <span>{{ estimateTime }} minutes</span>
          </p>
        </ion-col>

        <ion-col
          size="2"
          class="mid-center-content pointer ion-text-center ion-activatable ripple-parent rounded-rectangle"
        >
          <!-- <ion-icon
            name="wallet-outline"
            (click)="checkIfUserLoggedIn('account/wallet', 'wallet')"
          ></ion-icon> -->
          <!-- <svg class="chat-icon" (click)="categoryModal()"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <g fill="none">
              <path
                stroke="currentColor"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9.882 15C13.261 15 16 12.538 16 9.5S13.261 4 9.882 4C6.504 4 3.765 6.462 3.765 9.5c0 .818.198 1.594.554 2.292L3 15l3.824-.736A6.62 6.62 0 0 0 9.882 15Z"
              />
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10.804 18.124a6.593 6.593 0 0 0 3.314.876a6.623 6.623 0 0 0 3.059-.736L21 19l-1.32-3.208a5.02 5.02 0 0 0 .555-2.292c0-1.245-.46-2.393-1.235-3.315c-.749-.89-1.792-1.569-3-1.92"
              />
              <circle
                r="1"
                fill="currentColor"
                transform="matrix(-1 0 0 1 13 9.5)"
              />
              <circle
                r="1"
                fill="currentColor"
                transform="matrix(-1 0 0 1 10 9.5)"
              />
              <circle
                r="1"
                fill="currentColor"
                transform="matrix(-1 0 0 1 7 9.5)"
              />
            </g>
          </svg> -->
          <app-support-icon [parentScreenName]="'Homepage'"></app-support-icon>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-col>

        <ion-col
          size="1"
          class="mid-center-content user-icon pointer ion-text-end ion-activatable ripple-parent rounded-rectangle"
        >
          <ion-icon
            (click)="checkIfUserLoggedIn('account', 'account')"
            name="person-circle-outline"
          ></ion-icon>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-col>
      </ion-row>

      <ion-row
        class="location-address ion-activatable ripple-parent rounded-rectangle"
        (click)="changeCurrentLocation()"
      >
        <ion-col size="1" class="ion-text-center">
          <ion-icon name="location"></ion-icon>
        </ion-col>
        <ion-col size="11">
          <div class="text-with-icon">
            <p class="truncated-text">{{ currentAddress | titlecase }}</p>
            <span class="dropdown-icon"
              ><ion-icon name="caret-down-outline"></ion-icon
            ></span>
          </div>
        </ion-col>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-row>
    </ion-grid>
  </div>

  <ion-grid>
    <ion-row
      class="search-field ion-activatable ripple-parent rounded-rectangle"
      (click)="goToSearch()"
    >
      <ion-col size="1" class="ion-text-center">
        <ion-icon name="search-outline"></ion-icon>
      </ion-col>
      <ion-col size="11">
        <ion-input
          readonly="true"
          type="text"
          placeholder="{{ 'search_placeholder_category' | translate }}"
        ></ion-input>
      </ion-col>
      <ion-ripple-effect></ion-ripple-effect>
    </ion-row>
  </ion-grid>

  <ion-grid class="product-availability ion-margin-top">
    <ion-row>
      <ion-col size="4">
        <div
          class="outline-shadow ion-activatable ripple-parent rounded-rectangle"
        >
          <ion-row (click)="openWhatsAppChat()">
            <ion-col size="4" class="order-icon">
              <ion-icon name="logo-whatsapp"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <h6 class="sub-title">Order on</h6>
              <h6><b>Whatsapp</b></h6>
            </ion-col>
          </ion-row>
          <ion-ripple-effect></ion-ripple-effect>
        </div>
      </ion-col>
      <ion-col size="4">
        <div
          class="outline-shadow mid-auto ion-activatable ripple-parent rounded-rectangle"
        >
          <ion-row (click)="contactStoreSupport()">
            <ion-col size="4" class="order-icon">
              <ion-icon name="call-outline"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <h6 class="sub-title">Order on</h6>
              <h6><b>Call</b></h6>
            </ion-col>
          </ion-row>
          <ion-ripple-effect></ion-ripple-effect>
        </div>
      </ion-col>
      <ion-col size="4">
        <div
          class="outline-shadow right-auto ion-activatable ripple-parent rounded-rectangle"
        >
          <ion-row (click)="buyatstoreModal()">
            <ion-col size="4" class="order-icon">
              <ion-icon name="storefront-outline"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <h6 class="sub-title">Buy at</h6>
              <h6><b>Store</b></h6>
            </ion-col>
          </ion-row>
          <ion-ripple-effect></ion-ripple-effect>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-header>
<!-- -----------end------------ -->
<!-- --------skeleton---------- -->
<div *ngIf="!isLoaded">
  <app-header-skeleton></app-header-skeleton>
</div>
<!-- ----------end---------------- -->
