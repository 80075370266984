<!-- <div class="celebration-section">
  <ion-row>
    <ion-col size="12">
       <h4>Celebrate Teej</h4>
       <p>Lowest prices!</p>

    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="4">
        <div class="celebration-box-1"></div>
    </ion-col>
    <ion-col size="4">
      <div class="celebration-box-1"></div>
    </ion-col>
    <ion-col size="4">
      <div class="celebration-box-1"></div>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col size="4">
        <div class="celebration-box-1"></div>
    </ion-col>
    <ion-col size="4">
      <div class="celebration-box-1"></div>
    </ion-col>
    <ion-col size="4">
      <div class="celebration-box-1"></div>
    </ion-col>
  </ion-row>

 </div> -->

<div class="celebration-section"
  [ngStyle]="{'background': sectionData?.background?.type === 'gradient' ? 'linear-gradient(' + sectionData?.background?.color1 + ', ' + sectionData?.background?.color2 + ')' : sectionData?.background?.color1}">
  <ion-row>
    <ion-col size="12">
      <h4 [ngStyle]="{'color': sectionData?.titleTextColor}">
        {{ sectionData?.title }}
      </h4>
      <p [ngStyle]="{'color': sectionData?.subtitleTextColor}">
        {{ sectionData?.subtitle }}
      </p>
    </ion-col>
  </ion-row>

  <!-- <ion-row>
    <ion-col *ngFor="let tile of sectionData?.tiles" size="4">
      <div class="celebration-box" [ngStyle]="{'background-color': tile?.backgroundColor}">
        <div class="image-container">
          <ion-img [src]="tile?.imageUrl" alt="" (click)="handleTileClick(tile?.navigationLink)"></ion-img>
          <p *ngIf="tile?.text" class="overlay-text" [ngStyle]="{'color': tile?.textColor, 'background-color': tile?.textBackgroundColor}">
            {{ tile?.text }}
          </p>
        </div>
      </div>
    </ion-col>
  </ion-row> -->

  <ion-row>
    <ion-col *ngFor="let tile of sectionData?.tiles" size="4">
      <div class="celebration-box">
        <div class="image-container">
          <ion-img [src]="tile?.imageUrl" alt="" (click)="handleTileClick(tile?.navigationLink)"></ion-img>
        </div>
      </div>
    </ion-col>
  </ion-row>
  
</div>