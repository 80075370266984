<!-- -------------product-details--page---------------- -->
<ion-header [translucent]="true" *ngIf="isLoaded">
  <div class="header-title">
    <ion-grid>
      <ion-row>
        <ion-col size="1">
          <ion-icon
            name="arrow-back"
            class="back-arrow"
            (click)="modalController.dismiss()"
          ></ion-icon>
        </ion-col>
        <ion-col size="11">
          <h5 class="location">
            {{ productData?.name | titlecase }}
          </h5>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-header>

<!-- ------------header--skeleton------------ -->
<ion-header *ngIf="!isLoaded">
  <ion-row>
    <ion-col size="12" class="ion-padding-top">
      <ion-skeleton-text
        [animated]="true"
        style="width: 30%; margin-bottom: 0.5rem"
      ></ion-skeleton-text>
      <ion-skeleton-text
        [animated]="true"
        style="width: 65%"
      ></ion-skeleton-text>
    </ion-col>
  </ion-row>
</ion-header>
<!-- -----------------end----------------- -->

<ion-content>
  <div *ngIf="isLoaded">
    <div class="single-slider">
      <swiper-container
        #swiper
        (afterinit)="swiperReady()"
        (slidechange)="swiperSlideChangeded($event)"
        [loop]="true"
        [pagination]="true"
        zoom="true"
        *ngIf="productImages.length > 0"
      >
        <swiper-slide *ngFor="let item of productImages">
          <!-- <ion-img
            src="{{ item }}"
            alt="product-image"
            (ionError)="loadDefaultImg($event)"
          /> -->
          <div class="swiper-zoom-container">
            <img
              src="{{ item }}"
              alt="product-image"
              (error)="loadDefaultImg($event)"
            />
          </div>
        </swiper-slide>
      </swiper-container>
      <ng-container *ngIf="productImages.length === 0">
        <ion-img
          src="{{ productImages[0] }}"
          alt="product-image"
          (ionError)="loadDefaultImg($event)"
        />
      </ng-container>
      <!-- whatsapp-icon -->
      <!-- <div class="whatsapp-icon"><ion-icon name="logo-whatsapp"></ion-icon></div> -->
      <div class="item-discount">
        <small *ngIf="productData?.mrp > productData?.discountedPrice">{{
          productData?.discountLabel
        }}</small>
      </div>
    </div>
    <ion-grid class="title-box">
      <ion-row>
        <ion-col size="12">
          <div>
            <h5>{{ productData?.name | titlecase }}</h5>
          </div>
          <div>
            <small class="quantity">{{ productData.size }}</small>
          </div>
        </ion-col>

        <ion-col size="8" class="less-price">
          <h6>
            &#8377; {{ productData?.discountedPrice }}
            <small *ngIf="productData?.mrp > productData?.discountedPrice"
              >&#8377; {{ productData?.mrp }}</small
            >
          </h6>
        </ion-col>
        <ion-col size="4">
          <div *ngIf="!isInCart && productData?.stock > 0">
            <ion-button
              expand="block"
              class="custom-btn"
              (click)="checkIfUserLoggedIn(productData._id)"
              >Add
            </ion-button>
          </div>

          <div *ngIf="!isInCart && productData?.stock === 0">
            <ion-button 
            expand="block"
            fill="clear"
            class="custom-out-of-stock-btn"
            >Out of stock
          </ion-button>
          </div>

          <ion-row *ngIf="isInCart" class="add-to-cart">
            <ion-col
              size="4"
              class="minus-btn pointer ion-activatable ripple-parent rounded-rectangle"
            >
              <ion-icon
                name="remove-outline"
                (click)="decreaseProductQuantity(productData)"
              ></ion-icon>
              <ion-ripple-effect></ion-ripple-effect>
            </ion-col>
            <ion-col size="4" class="product-quantity">
              <span>{{ productQuantity }}</span>
            </ion-col>
            <ion-col
              size="4"
              class="add-btn pointer ion-activatable ripple-parent rounded-rectangle"
            >
              <ion-icon
                name="add-outline"
                (click)="increaseProductQuantity(productData)"
                class="pointer"
              ></ion-icon>
              <ion-ripple-effect></ion-ripple-effect>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="productData.isSubscription">
        <ion-col size="12">
          <p class="ion-no-margin ion-no-padding ion-text-end">
            <a
              (click)="goToSub('/subscription/product/' + productId)"
              class="startSub"
              >Start Subscription</a
            >
          </p>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="similarProductData?.length > 0">
        <ion-col size="12">
          <h4>Similar Products</h4>
        </ion-col>
      </ion-row>
    </ion-grid>

    <div class="multi-slider ion-margin-start">
      <swiper-container #swiper2 [slidesPerView]="3" [loop]="false">
        <swiper-slide *ngFor="let product of similarProductData">
          <app-product-card
            [isOpenFromModal]="true"
            [cardType]="'list'"
            [productData]="product"
            [parentScreenName]="'Similar products in product detail'"
            (loadNewEvent)="loadNewProduct($event)"
          ></app-product-card>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
  <app-productdetails-skeleton *ngIf="!isLoaded"></app-productdetails-skeleton>
  <!-- --------------------------Add to cart footer-------------------------------------------- -->
</ion-content>
<!-- --------------------------Add to cart footer-------------------------------------------- -->
<ion-footer>
  <app-footer></app-footer>
</ion-footer>
<!-- <ion-footer *ngIf="isInCart">
  <ion-grid>
    <ion-row>
      <ion-col size="7" class="mrp-section">
        <h4>
          <b>&#8377;{{ productData?.discountedPrice }}</b>
        </h4>
        <span
          *ngIf="productData?.mrp > productData?.discountedPrice"
          class="text-gray"
          ><small
            ><del>&#8377; {{ productData?.mrp }}</del></small
          ></span
        >
      </ion-col>
      <ion-col size="5">
        <ion-button (click)="navigateToCart()" expand="block" class="common-btn"
          >Buy
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer> -->
<!-- ------------------end----------------- -->
