// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://dev.daykart.com/api/user',
  //apiUrl: 'http://localhost:3002/api/user',
  // apiUrl : 'https://www.daykart.com/api/user',
  apiKey: 'AIzaSyDaL0tJ1DxztDBCKWKT7ufUFHIhEv6P2NE',
  appVersion: '4.1.10',
  googleMapZoom: 18,
  // apiUrl : 'http://192.168.1.5:3002/api/user',
  // apiUrl : 'https://7b5c-122-161-53-33.ngrok-free.app/api/user',
  apiDomain: 'https://daykart.algogenix.in',
  googleAnalyticsTrackingId: 'G-F3WBTF8MVR',
  whatsapp_number: '919920000000',
  mixpanelToken:'eba169c482f5f4a7ca65718fdff8e32d',
  googlePlayStoreUrl: 'https://play.google.com/store/search?q=daykart&c=apps&hl=en' ,
  iosAppStoreUrl: 'https://apps.apple.com/in/app/daykart/id6505065736',
  algoliaSearch:{
    dbIndex:'dev_daykart',
    inactiveProductIndex:'dev_daykart_inactive_products',
    suggestionIndex:'dev_daykart_query_suggestion',
    categorySuggestionIndex:'dev_daykart_category_suggestion',
    applicationId:'7W8C6PHWPX',
    apiKey:'d320ddf47400ec240f42cd83b6fa20b9'
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
