<!-- ------------your--cart-------------- -->
<app-modal-header
  [title]="'Your Cart'"
  [subTitle]="cartProduct?.length > 0 ? '(' + cartProduct?.length + ')' : ''"
></app-modal-header>

<ion-content class="common-bg-color ion-padding-horizontal">
  <!-- -------product--list-section------- -->
  <app-product-card
    *ngFor="let product of cartProduct"
    [cardType]="'cart'"
    [productData]="product"
    [showCartButtons]="true"
    [parentScreenName]="'Cart Items popup in footer'"
    (messageEvent)="refreshCartModalList($event)"
  ></app-product-card>
  <!-- ---------------end---------------- -->
</ion-content>
<ion-footer>
  <div class="add-item" *ngIf="cartCount > 0">
    <ion-grid>
      <ion-row>
        <ion-col size="2" sizeMd="1" sizeLg="1">
          <ion-img
            src="{{ imageForDisplay }}"
            (ionError)="loadDefaultImg($event)"
          />
        </ion-col>
        <ion-col size="5" class="pointer">
          <h5>
            <b
              >{{ cartCount }}
              {{
                cartCount === 1 ? ("item" | translate) : ("items" | translate)
              }}
            </b>
          </h5>
        </ion-col>

        <ion-col size="5">
          <ion-button
            expand="block"
            class="common-btn"
            (click)="navigateToCart()"
            >{{ "buy" | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-footer>
