<app-title-header
  [title]="'Order Info'"
  [backButtonRoute]="'/cart'"
  [parentScreenName]="'Order Processing'"
></app-title-header>

<ion-content class="ion-padding-horizontal">
<!-- ---------order-success--------- -->
  <div class="order-success" *ngIf="orderStatus === 'success'">
    <ion-row>
      <ion-col size="12">
        <ion-img src="../../../../../assets/img/daykart/check-1.png" />
      </ion-col>
    </ion-row>
    <ion-row class="ion-margin-top">
      <ion-col size="12">
        <h4>Order Placed Successfully</h4>
      </ion-col>

      <ion-col size="12">
        <p>Order ID</p>
        <small class="text-gray">{{userOrderId}}</small>
        <p class="ion-padding-top">Order Date & Time</p>
        <small class="text-gray">{{
            (orderTime | date : "mediumDate") +
              "; " +
              (orderTime | date : "shortTime")
          }}</small>
      </ion-col>
    </ion-row>
  </div>
<!-- -------order-failure------------- -->
  <div class="order-failure" *ngIf="orderStatus === 'failure'">
    <ion-row>
      <ion-col size="12">
        <ion-img src="../../../../../assets/img/daykart/close.png" />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-text-center">
        <h4>Order Not Placed</h4>
        <small class="text-gray">We couldn't place your order. Please go back to your cart and try again.</small>
      </ion-col>
    </ion-row>
  </div>
</ion-content>

<ion-footer class="common-margin">
  <ng-container *ngIf="orderStatus === 'success'">
    <ion-button expand="block" class="common-btn" (click)="trackOrder()"> Track Order </ion-button>
    <ion-button (click)="redirectToHome()" expand="block" class="custom-outline-btn ion-margin-top">
      Go to Home
    </ion-button>
  </ng-container>

  <ng-container *ngIf="orderStatus === 'failure'">
    <ion-button expand="block" class="common-btn" (click)="returnToCart()"> Back to Cart</ion-button>
  </ng-container>
 
</ion-footer>
