import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  userLogout(){
    return this.http.post(environment.apiUrl + `/logout`, {});
  }

  walletRecharge(body: any) {
    return this.http.post(environment.apiUrl + `/wallet/recharge`, body);
  }
}
