import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonInfiniteScroll, ModalController, NavController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { RequestProductComponent } from 'src/app/layout/request-product/request-product.component';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { HomeService } from 'src/app/services/home.service';
import { ProductService } from 'src/app/services/product.service';
import { SearchService } from 'src/app/services/search.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.scss'],
})
export class SearchProductComponent implements OnInit, ViewWillEnter, ViewWillLeave {

  page: number = 1;
  limit: number = 12;
  totalPages: number = 0;
  loadingText: string = 'loading products...'
  @ViewChild(IonInfiniteScroll) infiniteScroll!: IonInfiniteScroll;
  public loaded = false;

  searchInput: any = '';
  dataFound: any = 0; // 0->default 1->no relevant data found 2->data found

  productData: any = [];
  isLoaded: boolean = false;

  bannerId: string = '';
  pageType: string = 'search';

  returnUrl: string = '/';
  activeSubCategoryId: string = ''

  // Algolia search variables

  totalResultPages: number = 0;

  isSuggestionBoxVisible = true;
  queryId: any;

  currentActiveSearchQueryId: any;
  currentInactiveSearchQueryId: any;


  categoryQueryId: any;
  productQueryId: any;

  @ViewChild(IonContent) content!: IonContent;

  constructor(private productService: ProductService,
    private router: Router,
    private modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService,
    private location: Location,
    private utilityService: UtilityService,
    private navController: NavController,
    private mixpanelService: MixedPanelsService,
    private searchService: SearchService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.mixpanelService.track('Page Viewed', { page: 'Search' });


    let subscription = this.activatedRoute.queryParams.subscribe((params: any) => {
      const query = params['q'] || '';
      if (query) {
        this.searchInput = query;
        this.searchInProducts()
      }

      const returnUrl = params['returnUrl']
      const subCatId = params['activeSubcategoryId']
      if (returnUrl) {
        this.returnUrl = returnUrl;
      }
      if (subCatId) {
        this.activeSubCategoryId = subCatId
      }
    })

    subscription.unsubscribe(); //unsubscribe immediately because param should change on successful searches only

  }

  searchClient: any;
  search: any;



  @ViewChild('searchBar', { read: ElementRef }) searchBar: ElementRef | undefined;

  ionViewWillEnter(): void {
    this.resetPagination()
    this.setFocusOnSearchBar();
  }

  ionViewWillLeave() {
    // this.resetPagination();
    // this.searchInput = '';
    // this.setQueryToEmptyString();
    // // this.productData = [];
    // this.isLoaded = false;
    // this.dataFound = 0;
  }

  // displayBannerProducts() {
  //   this.homeService.bannerProductList(this.bannerId).subscribe((res: any) => {
  //     if (res?.success) {
  //       this.isLoaded = true;
  //       this.dataFound = 2;
  //       this.productData = res?.bannerProducts;
  //       this.totalPages = res?.pages;
  //     }
  //     else {
  //       this.isLoaded = false;
  //       this.dataFound = 1;
  //     }
  //   })
  // }

  navigateToComponent() {
    if (this.returnUrl === '/') {
      this.navController.setDirection('back');
      this.router.navigate(['/'])
    }
    else {
      console.log(this.activeSubCategoryId)
      this.navController.setDirection('back');
      this.router.navigate([this.returnUrl],
        // {
        //   queryParams: {
        //     activeSubcategoryId: this.activeSubCategoryId
        //   }
        // }
      )
      // const { path, queryParams } = this.utilityService.splitUrlAndQueryParams(this.returnUrl)
      // if (Object.keys(queryParams).length !== 0) {
      //   console.log('query route')
      //   this.navController.setDirection('back');
      //   this.router.navigate(path, { queryParams });
      // }
      // else {
      //   console.log('normal route')
      //   this.navController.setDirection('back');
      //   this.router.navigate(path)
      // }
    }
  }

  searchSuggestions: any = [];

  searchInProducts1() {
    this.resetPagination();
    if (this.searchInput === '' || this.searchInput.length === 1) {
      this.productData = [];
      this.isLoaded = false;
      this.dataFound = 0;
      this.searchSuggestions = [];
      // this.setQueryToEmptyString();
      // this.resetPagination();
      return;
    }
    else {
      // this.dataFound = -1;
      // this.isLoaded = false;
      // this.productService.productSearch(this.searchInput, this.page, this.limit).subscribe((res: any) => {
      //   if (res.success) {
      //     this.isLoaded = true;
      //     this.dataFound = 2;
      //     this.productData = res?.products;
      //     this.totalPages = res?.pages;
      //     this.setQueryToEmptyString();
      //     this.mixpanel.track('Search success', {
      //       'search_query': this.searchInput,
      //     });
      //             }
      //   else {
      //     this.mixpanel.track('Search failure', {
      //       'search_query': this.searchInput,
      //     });
      //     this.isLoaded = true;
      //     this.dataFound = 1;
      //     this.productData = [];
      //   }
      // })

      // this.searchService.search(this.searchInput,this.page, this.limit   ).then((res: any) => {
      //   if (res && res?.hits.length > 0) {
      //     this.isLoaded = true;
      //     this.dataFound = 2;
      //     this.productData = res?.hits;
      //     this.searchSuggestions = this.productData.slice(0, 7);

      //     this.totalPages = res?.nbPages;
      //     console.log(this.productData)
      //     this.mixpanel.track('Search success', {
      //       'search_query': this.searchInput,
      //     });
      //   } else {
      //     this.mixpanel.track('Search failure', {
      //       'search_query': this.searchInput,
      //     });
      //     this.isLoaded = true;
      //     this.dataFound = 1;
      //     this.productData = [];
      //     this.searchSuggestions = [];

      //   }
      // }).catch(error => {
      //   console.error('Search error:', error);
      //   this.mixpanel.track('Search failure', {
      //     'search_query': this.searchInput,
      //   });
      //   this.isLoaded = true;
      //   this.dataFound = 1;
      //   this.productData = [];
      //   this.searchSuggestions = [];
      // });

      this.searchService.getQuerySuggestions(this.searchInput, this.limit).then((res: any) => {
        if (res && res?.hits.length > 0) {
          this.isLoaded = true;
          this.dataFound = 2;
          this.searchSuggestions = res?.hits;

          // this.totalPages = res?.nbPages;
          // console.log(this.productData)
          // this.mixpanel.track('Search success', {
          //   'search_query': this.searchInput,
          // });
        } else {
          // this.mixpanel.track('Search failure', {
          //   'search_query': this.searchInput,
          // });
          this.isLoaded = true;
          this.dataFound = 1;
          this.productData = [];
          this.searchSuggestions = [];

        }
      }).catch(error => {
        console.error('Search error:', error);
        // this.mixpanel.track('Search failure', {
        //   'search_query': this.searchInput,
        // });
        // this.isLoaded = true;
        // this.dataFound = 1;
        // this.productData = [];
        // this.searchSuggestions = [];
      });
    }

  }

  // searchInProducts() {
  //   this.resetPagination();
  //   if (this.searchInput === '' || this.searchInput.length === 1) {
  //     this.productData = [];
  //     this.isLoaded = false;
  //     this.dataFound = 0;
  //     this.searchSuggestions = [];
  //     return;
  //   } else {
  //     // Start loading
  //     this.isLoaded = false;

  //     // Call search and suggestions concurrently
  //     Promise.all([
  //       this.searchService.searchActiveProducts(this.searchInput, this.page, this.limit),
  //       this.searchService.getQuerySuggestions(this.searchInput, this.limit)
  //     ])
  //       .then(([searchRes, suggestionRes]) => {
  //         // Handle search results
  //         if (searchRes && searchRes?.hits.length > 0) {
  //           this.productData = searchRes.hits;
  //           this.totalPages = searchRes.nbPages;
  //           this.mixpanel.track('Search success', { 'search_query': this.searchInput });
  //         } else {
  //           this.mixpanel.track('Search failure', { 'search_query': this.searchInput });
  //           this.productData = [];
  //         }

  //         // Handle suggestions
  //         if (suggestionRes && (suggestionRes?.categories?.hits.length > 0 || suggestionRes?.products?.hits.length > 0)) {
  //           this.searchSuggestions = [
  //             ...suggestionRes.products.hits,
  //             ...suggestionRes.categories.hits
  //           ];
  //           // this.queryId = suggestionRes?.queryID
  //           this.categoryQueryId = suggestionRes?.categories?.queryID;
  //           this.productQueryId = suggestionRes?.products?.queryID;

  //         } else {
  //           this.searchSuggestions = [];
  //         }

  //         // If either has results, set dataFound to 2, else 1
  //         this.dataFound = (searchRes?.hits.length > 0 || suggestionRes?.hits.length > 0) ? 2 : 1;
  //         this.isLoaded = true;
  //       })
  //       .catch(error => {
  //         console.error('Search error:', error);
  //         this.mixpanel.track('Search failure', { 'search_query': this.searchInput });
  //         this.isLoaded = true;
  //         this.dataFound = 1;
  //         this.productData = [];
  //         this.searchSuggestions = [];
  //       });
  //   }
  // }

  hideSuggestions() {
    setTimeout(() => {
      this.isSuggestionBoxVisible = false;
    }, 200);
  }

  showSuggestions() {
    this.isSuggestionBoxVisible = true;
    this.mixpanelService.track("b_search", { "screenName" : "Search Page"});
  }

  selectSuggestion(selectedSuggestionData: any, position: any) {
    this.searchSuggestions = [];
    this.isSuggestionBoxVisible = false;

    this.searchInput = selectedSuggestionData?.query;

    const eventData = {
      eventName: 'Query Suggestion Clicked',
      index: selectedSuggestionData.type === 'category' ? environment.algoliaSearch.categorySuggestionIndex : environment.algoliaSearch.suggestionIndex,
      objectIDs: [selectedSuggestionData?.objectID],
      positions: [position + 1],
      queryID: selectedSuggestionData.type === 'category' ? this.categoryQueryId : this.productQueryId,
    };

    console.log('event data:', eventData)

    this.searchService.sendClickEvent(eventData);
    this.setQueryToEmptyString();
    this.changeSearchInputFromSuggestion();
  }

  // changeSearchInputFromSuggestion() {
  //   this.searchService.searchActiveProducts(this.searchInput, this.page, this.limit).then((res: any) => {
  //     if (res && res?.hits.length > 0) {
  //       console.log('search result found', res)
  //       this.isLoaded = true;
  //       this.dataFound = 2;
  //       this.productData = res?.hits;
  //       this.totalPages = res?.nbPages;
  //       this.mixpanel.track('Search success', {
  //         'search_query': this.searchInput,
  //       });
  //     } else {
  //       this.mixpanel.track('Search failure', {
  //         'search_query': this.searchInput,
  //       });
  //       this.isLoaded = true;
  //       this.dataFound = 1;
  //       this.productData = [];
  //     }
  //   }).catch(error => {
  //     console.error('Search error:', error);
  //     this.mixpanel.track('Search failure', {
  //       'search_query': this.searchInput,
  //     });
  //     this.isLoaded = true;
  //     this.dataFound = 1;
  //     this.productData = [];
  //   });
  // }


  onSearchInputChange() {
    this.resetPagination();
  }




  // loadMoreProducts(event: any) {
  //   // if (this.page < this.totalPages) {
  //   //   this.page = this.page + 1;
  //   //   this.productService.productSearch(this.searchInput, this.page, this.limit).subscribe(
  //   //     (newData: any) => {
  //   //       this.productData = this.productData.concat(newData.products);
  //   //       event.target.complete();
  //   //     },
  //   //     (error) => {
  //   //       console.error('Error loading data:', error);
  //   //       event.target.complete();
  //   //     }
  //   //   );
  //   // }
  //   // else if (this.page === this.totalPages) {
  //   //   this.loadingText = 'No more content...'
  //   //   setTimeout(() => {
  //   //     event.target.disabled = true;
  //   //   }, 600);
  //   // }

  //   if (this.page < this.totalPages - 1) {
  //     this.page++;
  //     this.searchService.searchActiveProducts(this.searchInput, this.page, this.limit).then((newData: any) => {
  //       this.productData = this.productData.concat(newData.hits);
  //       event.target.complete();
  //     }).catch((error) => {
  //       console.error('Error loading data:', error);
  //       event.target.complete();
  //     });
  //   } else {
  //     this.loadingText = 'No more content...';
  //     setTimeout(() => {
  //       event.target.disabled = true;
  //     }, 600);
  //   }
  // }

  highlightMatch(query: string, suggestion: string): SafeHtml {
    if (!query) {
      return suggestion;
    }
    const regex = new RegExp(`(${query})`, 'gi');
    const highlighted = suggestion.replace(regex, '<strong>$1</strong>');
    return this.sanitizer.bypassSecurityTrustHtml(highlighted);
  }

  refreshSearch(messageStatus: boolean) {
    if (messageStatus) {
      this.dataFound = 0;
    }
  }


  async requestProduct() {
    let modal = await this.modalController.create({
      component: RequestProductComponent,
      cssClass: 'request-product-modal',
      componentProps: {
        productName: this.searchInput
      },
      backdropDismiss: true,
    })

    modal.onDidDismiss().then((data) => {
      if (data?.data?.status) {
        this.dataFound = 0
      }
    })

    return await modal.present();
  }

  setQueryToEmptyString() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { q: this.searchInput },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }


  setFocusOnSearchBar() {
    const searchInputElement = this.searchBar?.nativeElement.querySelector('input');
    if (searchInputElement) {
      searchInputElement.focus();
    }
  }

  reset() {
    this.resetPagination();
    this.searchInput = '';
    this.setQueryToEmptyString();
    // this.productData = [];
    this.isLoaded = false;
    this.dataFound = 0;

    this.searchSuggestions = [];
  }

  // resetPagination() {
  //   this.page = 1;
  //   this.limit = 12;
  //   this.totalPages = 0;
  // }




  loadDefaultImg(event: any) {
    this.productService.loadDefaultImg(event)
  }

  // --------------------------------------Algolia search functions----------------------------------------------

  activePage: number = 1;
  inactivePage: number = 1;

  activeTotalPages: number = 1;
  inactiveTotalPages: number = 1;

  searchInProducts() {
    this.resetPagination();
    if (this.searchInput === '' || this.searchInput.length === 1) {
      this.productData = [];
      this.isLoaded = false;
      this.dataFound = 0;
      this.searchSuggestions = [];
      this.setQueryToEmptyString();
      return;
    } else {
      // Start loading
      this.isLoaded = false;

      this.setQueryToEmptyString();
      // Call search and suggestions concurrently
      Promise.all([
        this.searchService.searchActiveProducts(this.searchInput, this.activePage, this.limit),
        this.searchService.getQuerySuggestions(this.searchInput, this.limit)
      ])
        .then(([activeRes, suggestionRes]) => {
          let combinedHits = activeRes.hits;
          let totalHits = activeRes.nbHits;

          this.currentActiveSearchQueryId = activeRes?.queryID;
          console.log("activeId", this.currentActiveSearchQueryId)

          // If less than limit results from active search, fetch from inactive
          if (combinedHits.length < this.limit) {
            console.log("result not enough")
            return this.searchService.searchInactiveProducts(this.searchInput, this.inactivePage, this.limit)
              .then(inactiveRes => {
                combinedHits = combinedHits.concat(inactiveRes.hits);
                totalHits += inactiveRes.nbHits;
                console.log("active res pages:", activeRes?.nbPages, "inactive res pages:", inactiveRes?.nbPages)
                // this.totalResultPages = activeRes.nbPages + inactiveRes.nbPages;
                this.inactiveTotalPages = inactiveRes?.nbPages;

                this.currentInactiveSearchQueryId = inactiveRes?.queryID;
                console.log("InactiveId", this.currentInactiveSearchQueryId)

                console.log('total pages', this.totalResultPages)
                return { combinedHits, totalHits, suggestionRes };
              });
          } else {
            // this.totalResultPages = activeRes.nbPages;
            this.activeTotalPages = activeRes.nbPages;

            return { combinedHits, totalHits, suggestionRes };
          }
        })
        .then(({ combinedHits, totalHits, suggestionRes }) => {
          if (combinedHits.length > 0) {
            console.log('search result found', combinedHits);
            this.isLoaded = true;
            this.dataFound = 2;
            this.productData = combinedHits;
            this.mixpanelService.track('Search success', {
              'search_query': this.searchInput,
            });

            this.mixpanelService.track("s_search_results", { "screenName" : "Search Page", "searchedTerm": this.searchInput});

          } else {
            this.mixpanelService.track('Search failure', {
              'search_query': this.searchInput,
            });
            this.mixpanelService.track("s_no_search_results", { "screenName" : "Search Page", "searchedTerm": this.searchInput});

            this.isLoaded = true;
            this.dataFound = 1;
            this.productData = [];
          }

          // Handle search suggestions
          if (suggestionRes && (suggestionRes.categories.hits.length > 0 || suggestionRes.products.hits.length > 0)) {
            this.searchSuggestions = [
              ...suggestionRes.products.hits,
              ...suggestionRes.categories.hits
            ];
            this.categoryQueryId = suggestionRes.categories.queryID;
            this.productQueryId = suggestionRes.products.queryID;
          } else {
            this.searchSuggestions = [];
          }
        })
        .catch(error => {
          console.error('Search error:', error);
          this.mixpanelService.track('Search failure', {
            'search_query': this.searchInput,
          });
          this.mixpanelService.track("s_no_search_results", { "screenName" : "Search Page", "searchedTerm": this.searchInput});
          this.isLoaded = true;
          this.dataFound = 1;
          this.productData = [];
          this.searchSuggestions = [];
        });
    }
  }


  loadMoreProducts(event: any) {
    console.log("inside pagination")
    console.log("active page current" + this.activePage, "active page total" + this.activeTotalPages)
    console.log("inactive page current" + this.inactivePage, "inactive page total" + this.inactiveTotalPages)

    if (this.activePage < this.activeTotalPages || this.inactivePage < this.inactiveTotalPages) {

      // Load more results from active index if there are more pages
      if (this.activePage < this.activeTotalPages) {
        this.activePage++;

        this.searchService.searchActiveProducts(this.searchInput, this.activePage, this.limit)
          .then(activeRes => {
            this.productData = this.productData.concat(activeRes.hits);
            if (activeRes.hits.length < this.limit) {
              return this.searchService.searchInactiveProducts(this.searchInput, this.inactivePage, this.limit);
            }
            return null;
          })
          .then(inactiveRes => {
            if (inactiveRes) {
              this.productData = this.productData.concat(inactiveRes.hits);
              this.inactiveTotalPages = inactiveRes?.nbPages;

              this.currentInactiveSearchQueryId = inactiveRes?.queryID;
              console.log("InactiveId", this.currentInactiveSearchQueryId)
              // this.totalResultPages += inactiveRes.nbPages;
              // this.inactivePage++;
            }
            this.isLoaded = true;
            event.target.complete();
          })
          .catch(error => {
            console.error('Error loading more results:', error);
            this.isLoaded = true;
            event.target.complete();
          });
      } else if (this.inactivePage < this.inactiveTotalPages) {
        console.log("pagination for inactive page", this.inactivePage, this.inactiveTotalPages)

        this.inactivePage++;

        // Load more results from inactive index if active pages are exhausted
        this.searchService.searchInactiveProducts(this.searchInput, this.inactivePage, this.limit)
          .then(inactiveRes => {
            this.productData = this.productData.concat(inactiveRes.hits);
            // this.inactivePage++;

            // this.totalResultPages = this.activePage
            this.isLoaded = true;
            event.target.complete();
          })
          .catch(error => {
            console.error('Error loading more results:', error);
            this.isLoaded = true;
            event.target.complete();
          });
      }
    } else {
      event.target.complete();
    }
  }

  changeSearchInputFromSuggestion() {
    this.searchService.searchActiveProducts(this.searchInput, this.activePage, this.limit)
      .then(activeRes => {
        let combinedHits = activeRes.hits;
        let totalHits = activeRes.nbHits;

        // If less than limit results from active search, fetch from inactive
        if (combinedHits.length < this.limit) {
          return this.searchService.searchInactiveProducts(this.searchInput, this.inactivePage, this.limit)
            .then(inactiveRes => {
              combinedHits = combinedHits.concat(inactiveRes.hits);
              totalHits += inactiveRes.nbHits;
              this.inactiveTotalPages = inactiveRes?.nbPages;

              return { combinedHits, totalHits };
            });
        } else {
          this.activeTotalPages = activeRes.nbPages;
          return { combinedHits, totalHits };
        }
      })
      .then(({ combinedHits, totalHits }) => {
        if (combinedHits.length > 0) {
          console.log('search result found', combinedHits);
          this.isLoaded = true;
          this.dataFound = 2;
          this.productData = combinedHits;
          this.mixpanelService.track('Search success', {
            'search_query': this.searchInput,
          });
          this.mixpanelService.track("s_search_results", { "screenName" : "Search Page", "searchedTerm": this.searchInput});
        } else {
          this.mixpanelService.track('Search failure', {
            'search_query': this.searchInput,
          });
          this.mixpanelService.track("s_no_search_results", { "screenName" : "Search Page", "searchedTerm": this.searchInput});

          this.isLoaded = true;
          this.dataFound = 1;
          this.productData = [];
        }
      })
      .catch(error => {
        console.error('Search error:', error);
        this.mixpanelService.track('Search failure', {
          'search_query': this.searchInput,
        });
        this.mixpanelService.track("s_no_search_results", { "screenName" : "Search Page", "searchedTerm": this.searchInput});
        this.isLoaded = true;
        this.dataFound = 1;
        this.productData = [];
      });
  }

  resetPagination() {
    this.activePage = 1;
    this.inactivePage = 1;
    this.activeTotalPages = 1;
    this.inactiveTotalPages = 1;
    this.limit = 12;
    this.totalResultPages = 0;
  }

}
