import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-desktop-footer',
  templateUrl: './desktop-footer.component.html',
  styleUrls: ['./desktop-footer.component.scss'],
})
export class DesktopFooterComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

  callStore(contactNo:string){
    window.open('tel:' + contactNo, '_system');
  }

  returnStoreUrl(storeType:string=''){
    if(storeType === 'android')
      return environment.googlePlayStoreUrl;
    else
      return environment.iosAppStoreUrl;
  } 

}
