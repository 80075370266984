import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';

@Component({
  selector: 'app-title-header',
  templateUrl: './title-header.component.html',
  styleUrls: ['./title-header.component.scss'],
})
export class TitleHeaderComponent  implements OnInit {
  @Input() title: any = ''
  @Input() backButtonRoute: any = '/';
  @Input() subTitle:string = '';

  @Input() parentScreenName:string='';
  
  constructor(
    public router: Router,
    private navController: NavController,
    private mixpanelService: MixedPanelsService
  ) { }

  ngOnInit() {}
  
  navigateToComponent() {
    if(this.router.url === '/cart/payment' && this.backButtonRoute === '/cart'){
      this.mixpanelService.track('Return from checkout', {});
    }
    this.navController.setDirection('back');
    this.router.navigateByUrl(this.backButtonRoute)
  }
}
