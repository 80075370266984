<!-- -------------header------------- -->
<ion-header [translucent]="true">
  <div class="header-title">
    <ion-grid>
      <ion-row>
        <ion-col size="1" class="ion-activatable ripple-parent rounded-rectangle">
          <ion-icon type="button"
            name="arrow-back"
            class="back-arrow"
            (click)="navigateToComponent()"
          ></ion-icon>
          <ion-ripple-effect></ion-ripple-effect>
        </ion-col>
        <ion-col size="10">
          <h5 class="location">
            {{ title | translate }}
            {{ subTitle }}
          </h5>
        </ion-col>
        <ion-col
        size="1"
        class="mid-center-content pointer ion-text-center ion-activatable ripple-parent rounded-rectangle"
      >
        <app-support-icon [parentScreenName]="parentScreenName"></app-support-icon>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-header>
<!-- --------------end-------------------- -->
