import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { OrderService } from 'src/app/services/order.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit,ViewWillEnter, ViewWillLeave {

  orderData: any;
  billData:any;
  orderedProducts:any=[];
  orderId: string = '';
  isLoaded: boolean = false;
  backUrl:any;


  constructor(private orderDetail: OrderService, private activatedRoute: ActivatedRoute,
    private router: Router, private productService:ProductService,
    private mixpanelService: MixedPanelsService
  ) { }

  ionViewWillEnter() {
    this.orderDetails();
  }

  ionViewWillLeave() {
    this.isLoaded = false;
  }
  

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((param) => {
      this.orderId = param['orderId'];
      const route = param['backButtonRoute']
      if(route){
        this.backUrl = route
      }
      else{
        this.backUrl = '/account/order-list'
      }
    })

    this.mixpanelService.track("s_order_summary", {})
  }

  orderDetails() {
    this.orderDetail.orderDetails(this.orderId).subscribe((res: any) => {
      if(res?.success){
        this.isLoaded = true;
        this.orderData = res?.data;
        this.orderedProducts = res?.data?.products;
        this.billData = res?.billDetails;
      }
    })
  }

  loadDefaultImg(event: any) {
    this.productService.loadDefaultImg(event)
  }

  callHelpline(mobile:string){
    this.mixpanelService.track("b_contact_us", { "screenName" : "Order Summary", "orderID": this.orderData?.orderID});
    window.open('tel:' + mobile, '_system');
  }

  routeTo(){
    this.router.navigateByUrl(this.backUrl)
  }

}
