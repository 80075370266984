<!-- --------------------------No address added--------------------------------- -->
<ion-row class="address-footer" *ngIf="!addressSelected">
  <ion-col size="1">
    <!-- <img src="{{checkAddressType(addressData?.addressType)}}" alt="" /> -->
    <ion-icon name="location-outline"></ion-icon>
  </ion-col>
  <ion-col size="8">
    <h6>
      <!-- {{addressData?.receiverName | titlecase}} <span class="distance">{{storeDistance}} km away</span> -->
      {{ "delivery_address" | translate }}
    </h6>
    <p class="text-gray-color">
      <small>{{ "select_delivery_address" | translate }}</small>
    </p>
  </ion-col>
  <ion-col size="3" class="ion-text-center ion-padding-top">
    <span #infoSpan (click)="openAddressModal()">{{ "select" | translate }}</span>
  </ion-col>
</ion-row>

<!-- ---------------------------After address selected-------------------------------------- -->
<ion-row class="address-footer" *ngIf="addressSelected">
  <ion-col size="1">
    <!-- <img src="{{checkAddressType(addressData?.addressType)}}" alt="" /> -->
    <ion-icon name="location-outline"></ion-icon>
  </ion-col>
  <ion-col size="8">
    <h6>
      <!-- {{addressData?.receiverName | titlecase}} <span class="distance">{{storeDistance}} km away</span> -->
      {{ "delivery_address" | translate }}
    </h6>
    <p class="text-gray-color">
      {{ addressData?.completeAddress + " " + addressData?.googleAddress }}
    </p>
  </ion-col>
  <ion-col size="3" class="ion-text-center ion-padding-top">
    <span (click)="openAddressModal()">{{ "change" | translate }}</span>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="6" class="amount-section">
    <h5>
      {{ "total" | translate }} <b>&#8377; {{ billingData?.grandTotal }}</b>
    </h5>
    <span
      >{{ "saving" | translate }}: &#8377; {{ billingData?.totalSavings }}
    </span>
  </ion-col>
  <ion-col size="6">
    <!-- <ion-button
      *ngIf="!addressSelected"
      expand="block"
      class="ion-margin-bottom common-btn"
      [disabled]="!addressSelected"
    >
      {{'proceed' | translate}}
    </ion-button> -->
    <ion-button
      *ngIf="!addressSelected"
      expand="block"
      class="ion-margin-bottom common-btn"
     (click)="promptAddressSelection($event)"
    >
      {{ "proceed" | translate }}
    </ion-button>

    <ion-button
      *ngIf="addressSelected"
      (click)="checkProductAvailabilityBeforeCheckout()"
      expand="block"
      class="ion-margin-bottom common-btn"
    >
      {{ "proceed" | translate }}
    </ion-button>
  </ion-col>
</ion-row>
<!-- ------------------end--------------------- -->
