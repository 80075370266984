import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonInfiniteScroll } from '@ionic/angular';
import { AccountService } from 'src/app/services/account.service';
import { MixedPanelsService } from 'src/app/services/extensions/mixed-panels.service';
import { ToastsService } from 'src/app/services/helpers/toasts.service';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';

declare var cordova: any;

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit {

  dataFound: number = 0;
  transactionData: any = [];
  walletBalance: number = 0;

  rechargeAmount: string = '';

  page: number = 1;
  limit: number = 10;
  totalPages: number = 0;
  loadingText: string = 'loading...'
  @ViewChild(IonInfiniteScroll) infiniteScroll!: IonInfiniteScroll;

  returnUrl: string = '';

  constructor(private router: Router,
    private storeService: StoreService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastsService,
    private accountService: AccountService,
    private cdRef: ChangeDetectorRef,
    private mixpanelService: MixedPanelsService
  ) { }

  ngOnInit() {
    this.resetPagination();
    this.activatedRoute.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'] || '/';
    })

    this.mixpanelService.track("s_my_wallet", {})

    this.transactionList();
  }

  transactionList() {
    this.storeService.walletTransactions().subscribe((res: any) => {
      if (res?.success) {
        this.dataFound = 1;
        this.walletBalance = res?.walletBalance;
        this.transactionData = res?.transactions;
        this.totalPages = res?.pages;
      }
      else {
        this.walletBalance = res?.walletBalance
        this.dataFound = -1;
      }
    })
  }

  loadNextTransactions(event: any) {
    if (this.page < this.totalPages) {
      this.page = this.page + 1;
      this.storeService.walletTransactions(this.page, this.limit).subscribe(
        (newData: any) => {
          this.transactionData = this.transactionData.concat(newData.transactions);
          event.target.complete();
        },
        (error) => {
          console.error('Error loading data:', error);
          event.target.complete();
        }
      );
    }
    else if (this.page === this.totalPages) {
      this.loadingText = 'No more content...'
      setTimeout(() => {
        event.target.disabled = true;
      }, 600);
    }
  }

  // --------------------------------------------Wallet recharege-------------------------------------

  setRechargeAmount(amount: string): void {
    this.rechargeAmount = amount;
    // this.cdRef.detectChanges()
  }

  rechargeWallet() {
    if (this.rechargeAmount === '' || this.rechargeAmount === '0') {
      this.toastService.showToast("Enter valid wallet amount")
    }
    else {
      this.mixpanelService.track("b_add_money", { "screenName" : "My wallet", "rechargeValue": this.rechargeAmount});

      const isCordova = localStorage.getItem('isCordova');
      if (isCordova === 'native') {
        this.payOnlineInApp();
      }
      else {
        this.payOnlineInBrowser2();
      }
    }

  }

  iabRef: any;
  url: any;

  payOnlineInApp() {
    const payload = {
      rechargeAmount: this.rechargeAmount,
    }

    this.accountService.walletRecharge(payload).subscribe((res: any) => {
      if (res.status) {
        var target = "_blank";
        const options = "beforeload=no,location=no,clearcache=no,clearsessioncache=no";
        this.url = res.redirectUrl
        localStorage.setItem('url', this.url)
        this.iabRef = cordova.InAppBrowser.open(this.url, target, options);
        localStorage.setItem('inAppBrowser', JSON.stringify(this.iabRef))
        this.iabRef.addEventListener("loadstop", (event: any) => {
          const url = localStorage.getItem('url');
          const iabRef = JSON.stringify(localStorage.getItem('inAppBrowser'))
          if (iabRef != undefined) {
            if (this.getBaseUrl(event.url) === `${environment.apiUrl}/wallet/recharge/success`) {

              // if (event.url === `${environment.apiUrl}/payment/success`) {
              setTimeout(() => {
                this.iabRef.close();
                this.reloadWallet()
                // this.router.navigate([`account/wallet`])
                // history.replaceState({}, '', '/');

              }, 5000);
            }
            else if (event.url === `${environment.apiUrl}/wallet/recharge/failure`) {
              setTimeout(() => {
                this.iabRef.close();
              }, 5000);
            }
          }
        });
        this.toastService.showToast(res?.message);


      }
      else {
        this.toastService.showToast(res?.message)
      }
    })
  }

  payOnlineInBrowser2() {
    const payload = {
      rechargeAmount: this.rechargeAmount,
    }

    this.accountService.walletRecharge(payload).subscribe((res: any) => {
      if (res.status) {

        const url = res.redirectUrl;
        localStorage.setItem('url', url);

        // Open the URL in a new window or tab using window.open()
        const windowRef: any = window.open(url, "_blank", "beforeload=no,location=no,clearcache=no,clearsessioncache=no");

        setTimeout(() => {
          if (windowRef !== null) {
            console.log('inside winref');
            windowRef.addEventListener('load', () => {
              console.log('inside load event');
              if (windowRef !== undefined) {
                alert(windowRef.location.href)
                if (this.getBaseUrl(windowRef.location.href) === `${environment.apiUrl}/wallet/recharge/success`) {
                  setTimeout(() => {
                    windowRef.close();
                    this.reloadWallet();
                  }, 1000);
                } else if (windowRef.location.href === `${environment.apiUrl}/wallet/recharge/failure`) {
                  setTimeout(() => {
                    windowRef.close();
                  }, 5000);
                }
              }
            });
          }
        }, 1000);
      }


      else {
        this.toastService.showToast(res?.message)
      }
    });
  }

  payOnlineInBrowser3() {
    const payload = {
      rechargeAmount: this.rechargeAmount,
    };
  
    this.accountService.walletRecharge(payload).subscribe((res: any) => {
      if (res.status) {
        const url = res.redirectUrl;
        localStorage.setItem('url', url);
  
        console.log('my url', url);
  
        // Open the URL in a new window or tab using window.open()
        const windowRef: any = window.open(url, "_blank", "noopener,noreferrer,beforeload=no,location=no,clearcache=no,clearsessioncache=no");
  
        if (windowRef) {
          const successUrl = `${environment.apiUrl}/wallet/recharge/success`;
          const failureUrl = `${environment.apiUrl}/wallet/recharge/failure`;
  
          const checkWindowLocation = () => {
            try {
              const currentUrl = windowRef.location.href;
  
              if (currentUrl.includes(successUrl)) {
                setTimeout(() => {
                  windowRef.close();
                  this.reloadWallet();
                }, 1000);
                return true; // stop polling
              } else if (currentUrl.includes(failureUrl)) {
                setTimeout(() => {
                  windowRef.close();
                }, 2000);
                return true; // stop polling
              }
            } catch (e) {
              // Catch and ignore any cross-origin errors
            }
            return false; // continue polling
          };
  
          // Poll the window's URL every second
          const intervalId = setInterval(() => {
            if (windowRef.closed || checkWindowLocation()) {
              clearInterval(intervalId);
            }
          }, 1000);
  
          // Clear interval on window unload
          window.addEventListener('beforeunload', () => {
            clearInterval(intervalId);
          });
        }
      } else {
        this.toastService.showToast(res?.message);
      }
    });
  }
  
  reloadWallet(){
    this.rechargeAmount = '';
    this.transactionList();
  }

  getBaseUrl(url: string) {
    return url.split('?')[0];
  }

  getQueryParamValue(url: string, paramName: string) {
    const urlObj = new URL(url);
    return urlObj.searchParams.get(paramName);
  }

  resetPagination() {
    this.page = 1;
    this.limit = 10;
    this.totalPages = 0;
  }

}
